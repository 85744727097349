import React from "react";
import Dropzone from "react-dropzone";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import Localizedtranslate from "react-localization";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import ProfilePreparingModal from "../../components/Modals/ProfilePreparingModal";
import "./Profile.css";

var unsubscribe;
var currentUserListener;

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Profile settings",
    subtitle: "Complete your profile to have your own creator's page.",
    yourName: "Your name",
    username: "Username",
    onlyOnce: "You can only set your username once.",
    shortIntro: "One line introduction",
    aboutMe: "About",
    aboutTips: "Write a few sentences about yourself.",
    photo: "Photo",
    change: "Change",
    creatorPageVideo: "Creator page video",
    uploadAFile: "Upload a file",
    uploadTips: "(.mp4 formant & vertical video only)",
    receivePayments: "Receive payments 💰",
    understandPolicy: "Understand our policy",
    policyDetails:
      "Just provide the most honest answer to your true followers. It's ok if you don't want to answer, your follower will get refunded. For successful answer, our platform will take 20% fee to better facilitate our platform.",
    priceYourAnswer: "Price your answer",
    forFree: "Answer for free?",
    notifications: "Notifications 🔔",
    notificationDetails:
      "We'll always let you know about important changes, but you pick what else you want to hear about.",
    notificationNoStripePart1:
      "First, you need to set up the Stripe or PayPal account to receive payments. Go to Billing's page to start the process.",
    notificationNoStripePart2:
      "If you haven't done so, the price of the question will be free.",
    goToPayments: "Go to Payment's page",
    newMessage: "New message",
    newMessageDesc: "Get notified when your fan sends you audio/video message.",
    weeklyNewsletter: "Weekly newsletter",
    weeklyNewsletterDesc:
      "Receive our weekly newsletter to keep everything updated.",
    save: "Save",
  },
  sp: {
    //Spanish
    title: "Configuración de perfil",
    subtitle: "Completa tu perfil para tener tu propia página de creador",
    yourName: "Tu nombre",
    username: "Nombre de usuario",
    onlyOnce: "Solo puede configurar su nombre de usuario una vez.",
    shortIntro: "Presentate en una línea.",
    aboutMe: "Sobre mí",
    aboutTips: "Escribe algunas frases sobre ti.",
    photo: "Foto",
    change: "Cambio",
    creatorPageVideo: "Video en la página del creador",
    uploadAFile: "Cargar un archivo",
    uploadTips: "(en formato .mp4 y video vertical solamente)",
    receivePayments: "Recibir pagos 💰",
    understandPolicy: "Entender nuestra política",
    policyDetails:
      "Simplemente proporciona la respuesta más honesta a tus verdaderos seguidores. Está bien si no quieres responder, tu seguidor recibirá un reembolso. Para una respuesta exitosa, nuestra plataforma cobrará una tarifa del 20%.",
    priceYourAnswer: "Ponle precio a tu respuesta.",
    forFree: "¿Responder gratis?",
    notifications: "Notificaciones 🔔",
    notificationDetails:
      "Siempre te informaremos sobre cambios importantes, pero tú eliges qué más quieres escuchar.",
    notificationNoStripePart1:
      "Primero, debe configurar una cuenta de Stripe o PayPal para recibir pagos. Vaya a la página de Facturación para iniciar el proceso.",
    notificationNoStripePart2:
      "Si no lo ha hecho, el precio de la pregunta será gratuito.",
    goToPayments: "Ir a la página de pago",
    newMessage: "Nuevo mensaje",
    newMessageDesc:
      "Recibe una notificación cuando tu fan te envíe un mensaje de audio / video.",
    weeklyNewsletter: "Boletín semanal",
    weeklyNewsletterDesc:
      "Reciba nuestro boletín semanal para mantenerte actualizado.",
    save: "Guardar",
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      viaPaymentSuccess:
        (this.props.location.state &&
          this.props.location.state.viaPaymentSuccess) ||
        null,
      currentUser: null,
      name: "",
      username: "",
      usernameSet: false,
      shortIntro: "",
      about: "",
      avatarFile:
        "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png",
      newAvatarFile: null,
      newAvatarURL: null,
      videoFile: null,
      newMessageNotification: true,
      newsletterNotification: true,
      understandPolicy: true,
      answerPrice: undefined,
      freeAnswer: false,
      isUpdating: false,
      uploadProgress: 0,
    };
  }

  componentDidMount() {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              currentUser: snapshot.val(),
              name: snapshot.val().name,
              username: snapshot.val().username,
              usernameSet: snapshot.val().username !== "" ? true : false,
              shortIntro:
                snapshot.val().shortIntro !== undefined
                  ? snapshot.val().shortIntro
                  : "",
              about:
                snapshot.val().about !== undefined ? snapshot.val().about : "",
              avatarFile: snapshot.val().avatar,
              answerPrice:
                snapshot.val().answerPrice === "undefined"
                  ? undefined
                  : snapshot.val().answerPrice,
              freeAnswer: snapshot.val().answerPrice === "free",
              newMessageNotification: snapshot.val().notification.newMessage,
              newsletterNotification: snapshot.val().notification.newsletter,
              isLoading: false,
            });
          });
      } else {
        this.props.history.push("/");
      }
    });
  }

  componentWillUnmount = async () => {
    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe();
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.id]: value });

    if (event.target.id === "newAvatarURL" && event.target.files[0]) {
      this.setState({
        newAvatarFile: event.target.files[0],
        newAvatarURL: window.URL.createObjectURL(event.target.files[0]),
      });
    }

    if (
      event.target.id === "newMessageNotification" ||
      event.target.id === "newsletterNotification" ||
      event.target.id === "understandPolicy" ||
      event.target.id === "freeAnswer"
    ) {
      this.setState({ [event.target.id]: event.target.checked });
    }
  };

  onSubmit = async () => {
    const {
      currentUser,
      name,
      username,
      usernameSet,
      shortIntro,
      about,
      newAvatarFile,
      videoFile,
      newMessageNotification,
      newsletterNotification,
      answerPrice,
      freeAnswer,
      understandPolicy,
    } = this.state;

    if (!understandPolicy) {
      toast.error("You need to confirm our policy first to accept payment.", {
        autoClose: 4000,
      });
      return;
    }

    if (answerPrice !== undefined && answerPrice < 10 && !freeAnswer) {
      toast.error("You cannot set price lower than $10.", {
        autoClose: 4000,
      });
      return;
    }

    this.setState({ isUpdating: true });

    if (!usernameSet) {
      const usernameLower = username.toLowerCase();

      //check if the username is valid in regex
      var usernameRegex = /^([a-zA-Z0-9\_]{2,})+$/;
      if (usernameLower.match(usernameRegex) === null) {
        //didn't pass the username validator, toast error and return
        if (usernameLower.length < 2) {
          toast.error("Username must be at least 2 characters long", {
            autoClose: 4000,
          });
          this.setState({ isUpdating: false });
          return;
        }
        toast.error('Your username can only contain letters, numbers and "_"', {
          autoClose: 4000,
        });
        this.setState({ isUpdating: false });
        return;
      }

      const usernameRef = await firebase
        .database()
        .ref("usernames")
        .child(usernameLower)
        .once("value");

      if (usernameRef.exists() === false) {
        await firebase //add new username
          .database()
          .ref("usernames")
          .child(usernameLower)
          .set({ uid: currentUser.uid });

        await firebase //update username in /users/{userId}
          .database()
          .ref("users")
          .child(currentUser.uid)
          .update({
            username: usernameLower,
            profileStatus: "preparing",
          });
      } else {
        //username is taken
        toast.error("Sorry, this username is taken.", {
          autoClose: 4000,
        });
        this.setState({ isUpdating: false });
        return;
      }
    }

    if (newAvatarFile) {
      const avatarStorageRef = await firebase
        .storage()
        .ref(username !== "" ? username : name)
        .child("profile")
        .child(newAvatarFile.name);
      const uploadedAvatar = await avatarStorageRef.put(newAvatarFile);
      const avatarDownloadURL = await uploadedAvatar.ref.getDownloadURL();

      await firebase.database().ref("users").child(currentUser.uid).update({
        avatar: avatarDownloadURL,
      });
    }

    if (videoFile) {
      const videoStorageRef = await firebase
        .storage()
        .ref(username !== "" ? username : name)
        .child("profile")
        .child(videoFile[0].path);
      const uploadedVideoTask = videoStorageRef.put(videoFile[0]);

      uploadedVideoTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ uploadProgress: Math.ceil(progress) });
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        async () => {
          // Handle successful uploads on complete
          uploadedVideoTask.snapshot.ref
            .getDownloadURL()
            .then(async (downloadURL) => {
              await firebase
                .database()
                .ref("users")
                .child(currentUser.uid)
                .update({
                  videoFile: downloadURL,
                });

              this.props.setUserValue({
                showProfilePreparingModal: true,
              });
            });

          await firebase
            .database()
            .ref("users")
            .child(currentUser.uid)
            .update({
              name: name,
              about: about,
              shortIntro: shortIntro,
              notification: {
                newMessage: newMessageNotification,
                newsletter: newsletterNotification,
              },
              answerPrice: freeAnswer
                ? "free"
                : answerPrice === undefined
                ? "undefined"
                : parseInt(answerPrice),
            });

          this.setState({ isUpdating: false, videoFile: null });
        }
      );
    } else {
      await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .update({
          name: name,
          about: about,
          shortIntro: shortIntro,
          notification: {
            newMessage: newMessageNotification,
            newsletter: newsletterNotification,
          },
          answerPrice: freeAnswer
            ? "free"
            : answerPrice === undefined
            ? "undefined"
            : parseInt(answerPrice),
        });

      toast("Your profile is updated 👏", {
        autoClose: 3000,
      });

      this.setState({ isUpdating: false });
    }
  };

  render() {
    translate.setLanguage("en");

    const {
      isLoading,
      currentUser,
      name,
      username,
      usernameSet,
      shortIntro,
      about,
      avatarFile,
      newAvatarURL,
      videoFile,
      newMessageNotification,
      newsletterNotification,
      understandPolicy,
      answerPrice,
      freeAnswer,
      isUpdating,
      viaPaymentSuccess,
      uploadProgress,
    } = this.state;

    const { showProfilePreparingModal } = this.props.user;

    if (isLoading) {
      return (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      );
    }

    const stripeObj = currentUser.stripe;

    // if (
    //   (!stripeObj ||
    //     (stripeObj.subscriptionStatus !== "trialing" &&
    //       stripeObj.subscriptionStatus !== "active") ||
    //     stripeObj.invoiceStatus !== "success") &&
    //   !viaPaymentSuccess
    // ) {
    //   return (
    //     <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 profile-page-locked-container">
    //       {" "}
    //       <form>
    //         <div>
    //           <div>
    //             <div className="bg-white py-5 border-b border-gray-200">
    //               <h3 className="text-4xl leading-9 font-semibold text-gray-900">
    //                 Profile Settings
    //               </h3>
    //               <p className="mt-2 text-md leading-5 text-gray-500">
    //                 Complete your profile to have your own creator's page.
    //               </p>
    //             </div>

    //             <div className="bg-white shadow sm:rounded-lg mt-4">
    //               <div className="px-4 py-5 sm:p-6">
    //                 <h3 className="text-lg leading-6 font-medium text-gray-900">
    //                   No active subscription found
    //                 </h3>
    //                 <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
    //                   <p>
    //                     Get started today! You'll have 14-day free trial and
    //                     30-day money-back guarantee.
    //                   </p>
    //                 </div>
    //                 <div className="mt-5">
    //                   <a
    //                     href="/pricing"
    //                     className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5"
    //                   >
    //                     Get started
    //                   </a>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   );
    // }

    return (
      <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24">
        {showProfilePreparingModal && <ProfilePreparingModal />}
        <form>
          <div>
            <div>
              <div className="bg-white py-5 border-b border-gray-200">
                <h3 className="text-4xl leading-9 font-semibold text-gray-900">
                  {translate.title}
                </h3>
                <p className="mt-2 text-md leading-5 text-gray-500">
                  {translate.subtitle}
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate.yourName}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      id="name"
                      className="flex-1 form-input block w-full min-w-0 rounded-md  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={name}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate.username}
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                        influenswer.com/
                      </span>
                      <input
                        id="username"
                        className={`flex-1 form-input block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                          usernameSet ? "bg-gray-50 text-gray-500" : ""
                        }`}
                        disabled={usernameSet}
                        value={username}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <p
                      className="mt-1 text-sm text-gray-500"
                      id="username-description"
                    >
                      <i className="fas fa-exclamation-circle fa-sm mr-1"></i>
                      {translate.onlyOnce}
                    </p>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="shortIntro"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate.shortIntro}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      id="shortIntro"
                      className="flex-1 form-input block w-full min-w-0 rounded-md  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={shortIntro}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate.aboutMe}
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <textarea
                      id="about"
                      rows="3"
                      className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      value={about}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    {translate.aboutTips}
                  </p>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="photo"
                    className="block text-sm leading-5 font-medium text-gray-700"
                  >
                    {translate.photo}
                  </label>
                  <div className="mt-2 flex items-center">
                    <span className="h-24 w-24 rounded-full overflow-hidden bg-gray-100">
                      {/* <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg> */}
                      <img
                        className="h-full w-full"
                        src={newAvatarURL || avatarFile}
                      />
                    </span>
                    <span className="ml-5 rounded-md shadow-sm">
                      <input
                        type="file"
                        accept="image/*"
                        name="newAvatarURL"
                        id="newAvatarURL"
                        className="newAvatarFile"
                        onChange={this.handleInputChange}
                      />
                      <label
                        htmlFor="newAvatarURL"
                        className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                      >
                        {translate.change}
                      </label>
                    </span>
                    {newAvatarURL && (
                      <span
                        className="ml-2 rounded-md shadow-sm cursor-pointer"
                        onClick={() =>
                          this.setState({
                            newAvatarURL: null,
                            newAvatarFile: null,
                          })
                        }
                      >
                        <label className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                          <i className="fas fa-undo fa-sm" />
                        </label>
                      </span>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="cover_video"
                    className="block text-sm leading-5 font-medium text-gray-700"
                  >
                    {translate.creatorPageVideo}
                  </label>
                  <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <Dropzone
                      name="uploadCreatorVideo"
                      multiple={false}
                      accept="video/*"
                      onDrop={(acceptedFiles) => {
                        this.setState({
                          videoFile: acceptedFiles.map((file) =>
                            Object.assign(file, {
                              preview: file,
                            })
                          ),
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            className="focus:outline-none"
                          >
                            <input {...getInputProps()} />
                            <div className="text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                />
                              </svg>
                              <p className="mt-1 text-sm text-gray-600">
                                <button
                                  type="button"
                                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1"
                                >
                                  {translate.uploadAFile}
                                </button>
                                {translate.uploadTips}
                              </p>
                            </div>
                          </div>
                          {videoFile && (
                            <aside className="flex flex-row text-center font-medium">
                              <ul className="mt-1 text-sm text-gray-600 mr-2">
                                {videoFile[0].path}
                              </ul>
                              <button
                                onClick={() =>
                                  this.setState({ videoFile: null })
                                }
                              >
                                <i className="far fa-trash-alt fa-sm"></i>
                              </button>
                            </aside>
                          )}
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-8">
              <div>
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                  {translate.receivePayments}
                </h3>
              </div>
              {(stripeObj && stripeObj.details_submitted) || // Stripe is set up
              (currentUser &&
                currentUser.paymentMethod === "paypal" &&
                currentUser.paypal &&
                currentUser.paypal.email !== "") ? ( // PayPal is set up
                <div className="mt-4 sm:grid sm:grid-cols-6">
                  <fieldset className="sm:col-span-6">
                    <div className="mt-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="understandPolicy"
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            checked={understandPolicy}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-5">
                          <label
                            htmlFor="understandPolicy"
                            className="font-medium text-gray-700"
                          >
                            {translate.understandPolicy}
                          </label>
                          <p className="text-gray-500">
                            {translate.policyDetails}
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="sm:col-span-4 mt-4">
                    <label
                      htmlFor="answerPrice"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      {translate.priceYourAnswer}
                    </label>
                    <div className="mt-1">
                      <div className="flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-base">
                          $
                        </span>
                        <input
                          id="answerPrice"
                          type="number"
                          min={10}
                          className={`flex-1 form-input block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                            freeAnswer ? "bg-gray-50 text-gray-500" : ""
                          }`}
                          value={answerPrice}
                          placeholder={"Minimum $10"}
                          onChange={this.handleInputChange}
                          disabled={freeAnswer}
                        />
                      </div>
                    </div>
                    <div className="mt-1 relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="freeAnswer"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          checked={freeAnswer}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-5">
                        <label
                          htmlFor="freeAnswer"
                          className="font-medium text-gray-700"
                        >
                          {translate.forFree}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-4 ">
                  <div>
                    <div className="mt-1 max-w-full text-sm leading-5 text-gray-500">
                      <p>
                        {translate.notificationNoStripePart1}{" "}
                        <span className="text-indigo-600">
                          {translate.notificationNoStripePart2}
                        </span>
                      </p>
                    </div>
                    <div className="mt-5">
                      <a
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5"
                        href="/payment"
                        target="_blank"
                      >
                        {translate.goToPayments}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-8 border-t border-gray-200 pt-8">
              <div>
                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                  {translate.notifications}
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-500">
                  {translate.notificationDetails}
                </p>
              </div>
              <div className="mt-4">
                <fieldset>
                  <div className="mt-4">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="newMessageNotification"
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          checked={newMessageNotification}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-5">
                        <label
                          htmlFor="newMessageNotification"
                          className="font-medium text-gray-700"
                        >
                          {translate.newMessage}
                        </label>
                        <p className="text-gray-500">
                          {translate.newMessageDesc}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="newsletterNotification"
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            checked={newsletterNotification}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-5">
                          <label
                            htmlFor="newsletterNotification"
                            className="font-medium text-gray-700"
                          >
                            {translate.weeklyNewsletter}
                          </label>
                          <p className="text-gray-500">
                            {translate.weeklyNewsletterDesc}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </form>
        <div className="mt-8 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                onClick={() => {
                  this.onSubmit();
                }}
              >
                {isUpdating ? (
                  videoFile ? (
                    `Uploading ${uploadProgress}%`
                  ) : (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                  )
                ) : (
                  `${translate.save}`
                )}
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Profile);
