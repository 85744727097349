import React from "react";
import "./NotFound.css";

class NotFound extends React.Component {
  render() {
    return (
      <div className="bg-white notfound-page-container">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-none lg:tracking-normal lg:text-6xl">
              Oops!
            </p>

            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              Sorry, we couldn't find the page you were looking for.
            </p>

            <img
              className="w-1/4 my-10 mx-auto rounded-lg"
              src="/assets/404-error.svg"
              alt="error"
            />

            <a
              href="/"
              className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
            >
              ← Back to homepage
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
