import React from "react";

class FreeTrialBanner extends React.Component {
  render() {
    return (
      <div className="relative bg-indigo-600">
        <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">14 days left on your trial.</span>
              <span className="hidden md:inline">
                Welcome onboard 👋 Your have 14 days left on your trial.
              </span>
              <span className="block sm:ml-2 sm:inline-block">
                <a href="#" className="text-white font-bold underline">
                  Subscribe now &rarr;
                </a>
              </span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 transition ease-in-out duration-150"
              aria-label="Dismiss"
            >
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeTrialBanner;
