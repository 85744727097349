import React from "react";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    faqTitle: "Frequently asked questions",
    faq1Title: "Who can become an Influenswer?",
    faq1Desc:
      "Anyone with an audience can join us. We prefer you are a domain expert in your own area. And you are willing to share your knowledge and values with your followers.",
    faq2Title: "What does it cost?",
    faq2Desc:
      "There is no monthly fee. For any successful answer, we charge a 20% service fee (a 3% transaction fee plus a 2% balance transfer fee is included), and influencers keep 80% of the earnings. We make money only when you do.",
    faq3Title: "How do I get paid?",
    faq3Desc:
      "We use Stripe and PayPal for payments. In order to receive payments, you need to connect to your Stripe account or your PayPal account first. As long as you have remaining balance in your Influenswer's account, you can request a transfer. And there is no minimums to request a transfer.",
    faq4Title: "What if I have no answer to the question?",
    faq4Desc:
      "It's totally OK if you don't know the answer or the question is inappropriate, you can simply reject the request in your own dashboard. You wouldn't get any penalty, and your follower will also get a full refund.",
  },
  sp: {
    //Spanish
    faqTitle: "Preguntas frecuentes",
    faq1Title: "¿Quién puede convertirse en Influenswer?",
    faq1Desc:
      "Cualquiera que tenga audiencia puede unirse a nosotros. Preferentemente que sea un experto en su área que esté dispuesto a compartir sus conocimientos y valores con sus seguidores.",
    faq2Title: "¿Cuanto cuesta?",
    faq2Desc:
      "No hay una cuota mensual. Por cualquier respuesta exitosa, cobramos una tarifa de servicio del 20% (Se incluye una tarifa de transacción del 3% más una tarifa de transferencia de saldo del 2%) y los influencers se quedan con el 80% de las ganancias. Ganamos dinero solo cuando tú lo haces.",
    faq3Title: "¿Cómo me pagan?",
    faq3Desc:
      "Usamos Stripe y PayPal para pagos. Para recibir pagos, primero debe conectarse a su cuenta de Stripe o su cuenta de PayPal. Siempre que tenga un saldo restante en su cuenta Influenswer, puede solicitar una transferencia. Y no hay mínimos para solicitar una transferencia.",
    faq4Title: "¿Qué pasa si no tengo respuesta a la pregunta?",
    faq4Desc:
      "Está totalmente bien si no sabe la respuesta o la pregunta es inapropiada, simplemente puede rechazar la solicitud en su propio panel de control. No recibirías ninguna penalización y tu seguidor también recibirá un reembolso completo.",
  },
});

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: 1,
    };
  }

  render() {
    translate.setLanguage("en");

    const { tabOpen } = this.state;

    return (
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              {translate.faqTitle}
            </h2>
            <div className="mt-6 border-t-2 border-gray-200 pt-6">
              <dl>
                <div>
                  <dt className="text-lg leading-7">
                    <button
                      className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
                      onClick={() =>
                        tabOpen === 1
                          ? this.setState({ tabOpen: 0 })
                          : this.setState({ tabOpen: 1 })
                      }
                    >
                      <span className="font-medium text-gray-900">
                        {translate.faq1Title}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <svg
                          className={`${
                            tabOpen === 1 ? "-rotate-180" : "rotate-0"
                          } h-6 w-6 transform`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </button>
                  </dt>
                  {tabOpen === 1 && (
                    <dd className="mt-2 pr-12 transition ease-in-out duration-500">
                      <p className="text-base leading-6 text-gray-500">
                        {translate.faq1Desc}
                      </p>
                    </dd>
                  )}
                </div>
                <div className="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt className="text-lg leading-7">
                      <button
                        className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
                        onClick={() =>
                          tabOpen === 2
                            ? this.setState({ tabOpen: 0 })
                            : this.setState({ tabOpen: 2 })
                        }
                      >
                        <span className="font-medium text-gray-900">
                          {translate.faq2Title}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <svg
                            className={`${
                              tabOpen === 2 ? "-rotate-180" : "rotate-0"
                            } h-6 w-6 transform`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    {tabOpen === 2 && (
                      <dd className="mt-2 pr-12 transition ease-in-out duration-500">
                        <p className="text-base leading-6 text-gray-500">
                          {translate.faq2Desc}
                        </p>
                      </dd>
                    )}
                  </div>
                </div>
                <div className="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt className="text-lg leading-7">
                      <button
                        className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
                        onClick={() =>
                          tabOpen === 3
                            ? this.setState({ tabOpen: 0 })
                            : this.setState({ tabOpen: 3 })
                        }
                      >
                        <span className="font-medium text-gray-900">
                          {translate.faq3Title}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <svg
                            className={`${
                              tabOpen === 3 ? "-rotate-180" : "rotate-0"
                            } h-6 w-6 transform`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    {tabOpen === 3 && (
                      <dd className="mt-2 pr-12 transition ease-in-out duration-500">
                        <p className="text-base leading-6 text-gray-500">
                          {translate.faq3Desc}
                        </p>
                      </dd>
                    )}
                  </div>
                </div>
                <div className="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt className="text-lg leading-7">
                      <button
                        className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
                        onClick={() =>
                          tabOpen === 4
                            ? this.setState({ tabOpen: 0 })
                            : this.setState({ tabOpen: 4 })
                        }
                      >
                        <span className="font-medium text-gray-900">
                          {translate.faq4Title}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <svg
                            className={`${
                              tabOpen === 4 ? "-rotate-180" : "rotate-0"
                            } h-6 w-6 transform`}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    {tabOpen === 4 && (
                      <dd className="mt-2 pr-12 transition ease-in-out duration-500">
                        <p className="text-base leading-6 text-gray-500">
                          {translate.faq4Desc}
                        </p>
                      </dd>
                    )}
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
