import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import getBlobDuration from "get-blob-duration";
import VideoRecorder from "react-video-recorder";
import { Player, ControlBar } from "video-react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import PulseLoader from "react-spinners/PulseLoader";
import "./RecordVideoModal.scss";
import Localizedtranslate from "react-localization";
import Confetti from "react-confetti";

import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardForm from "../Stripe/StripeForm";

let translate = new Localizedtranslate({
  en: {
    //English
    recordTitle: "Record your message 🎬",
    recordSubtitle:
      "I will try to give you the most honest answer. In case I don't know the answer, you will be refunded.",
    replyTitle: "Reply in a video message 🎬",
    replySubtitle:
      "I will try to give you the most honest answer. In case I don't know the answer, you will be notified as well.",
    startRecording: "Continue to record",
    uploadFile: "Or upload a file",
    reviewTitle: "Review your message",
    reviewSubtitle:
      "You need to provide your name and email in order to receive the reply.",
    yourName: "Your name",
    yourEmail: "Your email",
    emailRequired: "Email is required to receive a reply",
    nameRequired: "Name is required to receive a reply",
    badEmail: "Your email doesn't seem correct",
    cardNumber: "Card number",
    recordAgain: "Record again",
    confirmToPay: "By confirming, you agree to pay",
    confirmNotice: "You need to agree to proceed.",
    confirmAndSend: "Confirm and send",
    thankYou: "Thanks for your question! I will get back to you soon! 😉",
    close: "Close",
    replyDone: "You just answer your follower's question. Cheers 🍻",
    makeASuprise: "Make a suprise for your follower!",
  },
  sp: {
    //Spanish
    recordTitle: "Grabe su mensaje 🎬",
    recordSubtitle:
      "Intentaré darte la respuesta más honesta. En caso de que no sepa la respuesta, se le reembolsará.",
    replyTitle: "Responder en un mensaje de video 🎬",
    replySubtitle:
      "Intentaré darte la respuesta más honesta. En caso de que no sepa la respuesta, también se le notificará.",
    startRecording: "Continuar grabando",
    uploadFile: "O cargar un archivo.",
    reviewTitle: "Revisa tu mensaje",
    reviewSubtitle:
      "Debes proporcionar tu nombre y correo electrónico para recibir la respuesta.",
    yourName: "Tu nombre",
    yourEmail: "Tu correo electrónico",
    nameRequired: "Se requiere nombre para recibir una respuesta",
    emailRequired: "Se requiere correo electrónico para recibir una respuesta",
    badEmail: "Tu correo electrónico no parece correcto",
    cardNumber: "Número de tarjeta",
    recordAgain: "grabar de nuevo.",
    confirmToPay: "Confirmar para pagar",
    confirmNotice: "Necesita confirmar para continuar.",
    confirmAndSend: "Confirmar y enviar",
    thankYou:
      "¡Gracias por tu pregunta! Pronto me pondré en contacto con usted. 😉",
    close: "Cerrar",
    replyDone: "Simplemente responde la pregunta de tu seguidor. Salud 🍻",
    makeASuprise: "¡Sorprenda a su suscriptor!",
  },
});

const UpChunk = require("@mux/upchunk");

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function RecordVideoModal(props) {
  const [startRecordingButton, setStartRecordingButton] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [page, setPage] = useState("recording");
  const [progress, setProgress] = useState(0);
  const [muxUpload, setMuxUpload] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [followerName, setFollowerName] = useState("");
  const [followerEmail, setFollowerEmail] = useState("");
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadSucceed, setUploadSucceed] = useState(false);
  const [isBounty, setIsBounty] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [noName, setNoName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [noConfirm, setNoConfirm] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [stripeNoElements, setStripeNoElements] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");
  const [paypalProcessing, setPaypalProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const prevVideoBlob = usePrevious(videoBlob);
  const prevIsRecording = usePrevious(isRecording);

  translate.setLanguage("en");

  useEffect(() => {
    if (prevIsRecording === true && isRecording === false) {
      changePage();
    }
  }, [isRecording]);

  useEffect(() => {
    if (!prevVideoBlob && videoBlob) {
      onSetVideoBlob(videoBlob);
      onToggleRecordingBtn();
    }
  }, [videoBlob]);

  const { videoLimit, isReply } = props.user;

  const { creator } = props;

  const onCloseModals = () => {
    props.setUserValue({
      showSupportModal: false,
      showAudioModal: false,
      showVideoModal: false,
      creatorId: null,
      isDemo: false,
      videoLimit: null,
    });
  };

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const changePage = () => {
    setPage("review");
  };

  const onToggleRecordingBtn = (isFileUpload) => {
    setStartRecordingButton(!startRecordingButton);
    setIsFileUpload(isFileUpload);

    if (startRecordingButton) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const tryAgain = () => {
    setPage("recording");
  };

  const onSetVideoBlob = async (videoBlob) => {
    const { creatorId, isReply, replyTo } = props.user;

    const duration = await getBlobDuration(videoBlob);
    if (duration >= 131) {
      toast.error(
        "Oops, we already gave you 10s extra, but your video is too long 😭",
        {
          autoClose: 4000,
        }
      );
      tryAgain();
      return;
    }
    setVideoBlob(videoBlob);

    var upload = firebase.functions().httpsCallable("muxCreateUploadURL");
    upload({ creatorId: isReply ? replyTo.creatorId : creatorId }).then(
      (result) => {
        setMuxUpload(result.data);
      }
    );
  };

  const handleStripeSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setStripeNoElements(true);
      setStripeErrorMsg("No card provided.");
      return;
    }

    setIsPaymentProcessing(true);

    const createPaymentIntent = firebase
      .functions()
      .httpsCallable("stripeCreatePaymentIntent");

    const paymentIntent = await createPaymentIntent({
      productAmount: creator.answerPrice * 100, // in cents
      stripeAccId: creator.stripe.accountId,
    });

    const clientSecret = paymentIntent.data.client_secret;

    const cardPayment = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    setIsPaymentProcessing(false);

    return cardPayment;
  };

  const onContinueToPayPal = async () => {
    const { isDemo, isReply } = props.user;

    setStripeError(false);
    setIsUploading(true);
    setNoEmail(false);
    setNoName(false);
    setInvalidEmail(false);
    setNoConfirm(false);

    if (isDemo) {
      setPage("success");
      setIsUploading(false);
      return;
    }

    if (isBounty && !isReply && creator.answerPrice !== "free") {
      if (followerName === "") {
        setNoName(true);
        setIsUploading(false);
        return;
      }

      if (followerEmail === "") {
        setNoEmail(true);
        setIsUploading(false);
        return;
      } else {
        // not a valid email address
        if (validateEmail(followerEmail) === false) {
          setInvalidEmail(true);
          setIsUploading(false);
          return;
        }
      }
    }

    if (creator.answerPrice !== "free" && !confirmed && !isReply) {
      setNoConfirm(true);
      setIsUploading(false);
      return;
    }

    setPage("paypal");
  };

  const onUploadVideo = async () => {
    const { isDemo, isReply, replyTo } = props.user;

    setStripeError(false);
    setIsUploading(true);
    setNoEmail(false);
    setNoName(false);
    setInvalidEmail(false);
    setNoConfirm(false);

    if (isDemo) {
      setPage("success");
      setIsUploading(false);
      return;
    }

    if (!isReply) {
      if (followerName === "") {
        // check null name
        setNoName(true);
        setIsUploading(false);
        return;
      }

      if (followerEmail === "") {
        // check null email
        setNoEmail(true);
        setIsUploading(false);
        return;
      } else {
        // not a valid email address
        if (validateEmail(followerEmail) === false) {
          setInvalidEmail(true);
          setIsUploading(false);
          return;
        }
      }
    }

    if (!isReply && creator.answerPrice !== "free" && !confirmed) {
      // check empty confirm
      setNoConfirm(true);
      setIsUploading(false);
      return;
    }

    var paymentIntent;
    if (
      isBounty &&
      !isReply &&
      creator.answerPrice !== "free" &&
      creator.answerPrice !== "undefined"
    ) {
      const stripePayment = await handleStripeSubmit();

      if (stripePayment.error) {
        setStripeError(true);
        setIsUploading(false);
        setStripeErrorMsg(stripePayment.error.message);
        return;
      } else {
        // successful payment
        paymentIntent = stripePayment.paymentIntent;
        // save the payment intent id to the message for future refund reference
      }
    }

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([videoBlob], "newVideo");
    const upload = UpChunk.createUpload({
      file,
      endpoint: muxUpload.url,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });

    setPage("uploading-video");

    upload.on("success", async () => {
      if (isReply) {
        // the video is a reply from creator
        await firebase.database().ref("mux-videos").child(passthrough).update({
          type: "NEW_VIDEO_REPLY",
          additionalMessage: additionalMessage,
          name: replyTo.sentBy.name,
          email: replyTo.sentBy.email,
          messageId: replyTo.id,
        });
      } else {
        // the video is a message from follower
        await firebase
          .database()
          .ref("mux-videos")
          .child(passthrough)
          .update({
            type: "NEW_VIDEO_MESSAGE",
            additionalMessage: additionalMessage,
            name: followerName,
            email: followerEmail,
            isBounty: isBounty,
            price: creator.answerPrice || 0,
            paymentIntentId: paymentIntent ? paymentIntent.id : "",
            creatorName: creator.name,
          });
      }

      setIsUploading(false);
      setPage("success");
      setUploadSucceed(true);
    });

    upload.on("progress", (progress) => {
      setProgress(Math.ceil(progress.detail));
    });

    upload.on("error", (err) => {
      setIsUploading(false);
      setPage("success");
      setUploadSucceed(false);
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (event.target.id === "confirmed") {
      setConfirmed(event.target.checked);
    } else if (event.target.id === "followerName") {
      setFollowerName(value);
    } else if (event.target.id === "follwerEmail") {
      // followerEmail
      setFollowerEmail(value);
    } else {
      setAdditionalMessage(value);
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const createOrder = async (data, actions) => {
    const orderId = await actions.order.create({
      purchase_units: [
        {
          amount: {
            value: creator.answerPrice,
          },
        },
      ],
    });

    // console.log("order id", orderId); // log the orderId and creator email in the db

    return orderId;
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      setPage("uploading-video");
      const orderId = details.id;

      //upload video here for paypal case 👈
      const passthrough = muxUpload.new_asset_settings.passthrough;

      var file = new File([videoBlob], "newVideo");
      const upload = UpChunk.createUpload({
        file,
        endpoint: muxUpload.url,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      upload.on("success", async () => {
        // the video is a message from follower
        await firebase
          .database()
          .ref("mux-videos")
          .child(passthrough)
          .update({
            type: "NEW_VIDEO_MESSAGE",
            additionalMessage: additionalMessage,
            name: followerName,
            email: followerEmail,
            isBounty: isBounty,
            price: creator.answerPrice || 0,
            paymentIntentId: "",
            isPaypal: true,
            paypal: { orderId: orderId || "" },
            creatorName: creator.name,
          });

        setIsUploading(false);
        setPage("success");
        setUploadSucceed(true);
      });

      upload.on("progress", (progress) => {
        setProgress(Math.ceil(progress.detail));
      });

      upload.on("error", (err) => {
        setIsUploading(false);
        setPage("success");
        setUploadSucceed(false);
      });
    });
  };

  switch (page) {
    case "recording":
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            {isRecording ? (
              <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle md:max-w-sm"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="my-auto">
                  <VideoRecorder
                    isOnInitially
                    onRecordingComplete={(videoBlob) => {
                      setVideoBlob(videoBlob);
                    }}
                    timeLimit={videoLimit}
                    useVideoInput={isFileUpload}
                  />
                </div>
              </div>
            ) : (
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <button
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "5px",
                    zIndex: "999",
                    outline: "none",
                  }}
                  className="text-gray-400 rounded-full w-6 h-6"
                  onClick={() => onCloseModals()}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="times"
                    className="svg-inline--fa fa-times w-3 m-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 352 512"
                  >
                    <path
                      fill="currentColor"
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    ></path>
                  </svg>
                </button>
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                    <svg
                      className="h-6 w-6 text-indigo-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </div>

                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-semibold text-gray-900"
                      id="modal-headline"
                    >
                      {isReply ? translate.replyTitle : translate.recordTitle}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-500">
                        {isReply
                          ? translate.makeASuprise
                          : creator.answerPrice === "free" ||
                            !creator.answerPrice ||
                            creator.answerPrice === "undefined"
                          ? translate.replySubtitle
                          : translate.recordSubtitle}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <span className="flex justify-center rounded-md shadow-sm">
                    {startRecordingButton && (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={() => onToggleRecordingBtn(false)}
                      >
                        {translate.startRecording}
                      </button>
                    )}
                  </span>
                  <div className="mt-2 flex justify-center text-sm leading-5">
                    <button
                      className="px-2 bg-white text-gray-500 font-medium"
                      onClick={() => onToggleRecordingBtn(true)}
                    >
                      {translate.uploadFile}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    case "review":
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <button
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  zIndex: "999",
                  outline: "none",
                }}
                className="text-gray-400 rounded-full w-6 h-6"
                onClick={() => onCloseModals()}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="svg-inline--fa fa-times w-3 m-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
              </button>
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                  <svg
                    className="h-6 w-6 text-indigo-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900"
                    id="modal-headline"
                  >
                    {translate.reviewTitle}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      {translate.reviewSubtitle}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-4 flex items-center justify-center">
                {videoBlob && (
                  <Player className="rounded">
                    <source src={URL.createObjectURL(videoBlob)} />
                    <ControlBar />
                  </Player>
                )}
              </div>

              <div className="mt-4 w-full">
                <div className="rounded-md shadow-sm">
                  <textarea
                    id="additionalMessage"
                    rows="3"
                    placeholder="Additional message (optional)"
                    className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={additionalMessage}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              {!isReply && (
                <>
                  <div className="mt-4">
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="followerName"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder={translate.yourName}
                          value={followerName}
                          onChange={handleInputChange}
                        />
                      </div>
                      {noName && (
                        <p className="mt-1 text-sm text-red-500">
                          {translate.nameRequired}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="followerEmail"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder={translate.yourEmail}
                          value={followerEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                      {noEmail && (
                        <p className="mt-1 text-sm text-red-500">
                          {translate.emailRequired}
                        </p>
                      )}
                      {invalidEmail && (
                        <p className="mt-1 text-sm text-red-500">
                          {translate.badEmail}
                        </p>
                      )}
                    </div>
                  </div>

                  {isBounty &&
                    (!creator.paymentMethod || // by default is stripe
                      creator.paymentMethod === "stripe") &&
                    creator.answerPrice &&
                    creator.answerPrice !== "free" &&
                    creator.answerPrice !== "undefined" &&
                    creator.stripe &&
                    creator.stripe.details_submitted && (
                      <div className="mt-4">
                        <CardForm />
                        {(stripeError || stripeNoElements) && (
                          <p className="mt-1 text-sm text-red-500">
                            {stripeErrorMsg}
                          </p>
                        )}
                      </div>
                    )}

                  {creator.answerPrice &&
                    creator.answerPrice !== "free" &&
                    creator.answerPrice !== "undefined" && (
                      <div className="mt-4">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="confirmed"
                              type="checkbox"
                              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              checked={confirmed}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="ml-2 text-sm leading-5">
                            <label
                              htmlFor="confirmed"
                              className="font-medium text-gray-600"
                            >
                              I agree to pay $
                              {(creator.answerPrice * 105) / 100}. It includes a
                              ${creator.answerPrice} question fee, plus a
                              non-refundable ${(creator.answerPrice * 5) / 100}{" "}
                              processing fee.
                            </label>
                          </div>
                        </div>
                        {noConfirm && (
                          <p className="mt-1 text-sm text-red-500">
                            {translate.confirmNotice}
                          </p>
                        )}
                      </div>
                    )}
                </>
              )}

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                  {isReply ||
                  creator.answerPrice === "free" ||
                  (creator && !creator.paymentMethod) || // not yet set up payment method
                  (creator && // payment method set to Stripe
                    creator.paymentMethod === "stripe" &&
                    creator.stripe &&
                    creator.stripe.details_submitted) ||
                  (creator && // payment method chose PayPal but no email
                    creator.paymentMethod === "paypal" &&
                    !creator.paypal) ? (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => onUploadVideo()}
                    >
                      {muxUpload === null || isPaymentProcessing ? (
                        <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                      ) : isUploading ? (
                        `Uploading ${progress}%`
                      ) : (
                        `${translate.confirmAndSend}`
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => onContinueToPayPal()}
                    >
                      Continue to pay
                    </button>
                  )}
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-semibold text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => {
                      setPage("recording");
                      setVideoBlob(null);
                    }}
                  >
                    {translate.recordAgain}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    case "paypal":
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="text-left">
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900 mt-4"
                    id="modal-headline"
                  >
                    {paypalProcessing ? (
                      <PulseLoader sizeUnit={"px"} size={8} color={"#5850ec"} />
                    ) : (
                      "Pay with..."
                    )}
                  </h3>
                  <div className="mt-4">
                    <PayPalButtons
                      createOrder={(data, actions) =>
                        createOrder(data, actions)
                      }
                      onApprove={(data, actions) => onApprove(data, actions)}
                      onShippingChange={null}
                      shippingPreference="NO_SHIPPING"
                      forceReRender={2}
                      style={{ height: 40 }}
                    />
                  </div>

                  <div className="mt-2 ">
                    <span
                      className="underline cursor-pointer text-sm font-semibold text-gray-700 hover:text-gray-500 focus:underline transition ease-in-out duration-150"
                      onClick={() => setPage("review")}
                    >
                      Or go back
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case "uploading-video":
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-8"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full">
                  <img src="/assets/uploading.gif" />
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900"
                    id="modal-headline"
                  >
                    Uploading your video...
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Please do not close the page.
                    </p>
                  </div>
                </div>
              </div>

              <div className="relative pt-1 mt-6">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-sm font-semibold inline-block py-1 text-indigo-600 ">
                      Uploading...
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-sm font-semibold inline-block text-indigo-600">
                      {progress}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-3 mb-4 text-xs flex rounded bg-indigo-200">
                  <div
                    style={{ width: `${progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case "success":
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Confetti
              style={{ position: "fixed" }}
              recycle={false}
              gravity={0.5}
            />
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => onCloseModals()}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="text-center">
                  <div>
                    <img
                      className="mx-auto rounded"
                      src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
                      alt="success"
                    />
                  </div>
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900 mt-4"
                    id="modal-headline"
                  >
                    {isReply ? translate.replyDone : translate.thankYou}
                  </h3>
                </div>
              </div>
              <div className="mt-4">
                <span className="flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => onCloseModals()}
                  >
                    {translate.close}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RecordVideoModal);
