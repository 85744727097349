import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import Confetti from "react-confetti";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "./PaymentStatus.css";

var unsubscribe;
var currentUserListener;

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: true,
    };
  }

  componentDidMount = async () => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            const stripeObj = snapshot.val().stripe || {};
            if (
              (stripeObj.subscriptionStatus === "trialing" ||
                stripeObj.subscriptionStatus === "active") &&
              stripeObj.invoiceStatus === "success"
            ) {
              this.setState({
                isProcessing: false,
              });
            }
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentWillUnmount = async () => {
    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe();
  };

  render() {
    const { isProcessing } = this.state;

    return (
      <div className="bg-white payment-success-container">
        <Confetti style={{ position: "fixed" }} recycle={false} />
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-none lg:tracking-normal lg:text-6xl">
              You made it <span className="ml-1">🎉</span>
            </p>

            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              Thanks for choosing Influenswer! You can build your creator's page
              now, and allow your audience send you messages.
            </p>

            <img
              className="my-5 mx-auto rounded-lg"
              src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
              alt="success"
            />

            <button
              className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
              onClick={() =>
                this.props.history.push({
                  pathname: "/profile",
                  state: { viaPaymentSuccess: true },
                })
              }
            >
              Complete my profile →
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
