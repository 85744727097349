import React, { useState } from "react";
import Item from "./MessageItem";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    noMessage: "No new message",
    noArchive: "No archived message",
  },
  sp: {
    //Spanish
    noMessage: "Sin mensaje nuevo",
    noArchive: "Ningún mensaje archivado",
  },
});

function Messages(props) {
  translate.setLanguage("en");

  const {
    isOpen,
    setIsOpen,
    isDemo,
    user,
    archiveMsg,
    unarchiveMsg,
    setUserValue,
  } = props;

  switch (props.tab) {
    case "all":
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-12 md:col-span-8 md:w-3/4">
          <div className="mb-5 px-4 hidden lg:block">
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 ">
                    Total answered
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {isDemo
                      ? 120
                      : (user &&
                          user.stats &&
                          new Intl.NumberFormat("en-IN", {}).format(
                            user.stats.questions
                          )) ||
                        0}
                  </dd>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 ">
                    Current balance
                  </dt>
                  <dd className="mt-1 flex justify-between text-gray-900">
                    <div className="flex items-baseline text-3xl font-semibold mr-2">
                      $
                      {isDemo
                        ? 100
                        : (user &&
                            user.stats &&
                            new Intl.NumberFormat("en-IN", {}).format(
                              user.stats.balance
                            )) ||
                          0}
                    </div>

                    <button
                      type="button"
                      className="inline-flex items-center justify-center py-1 border border-transparent font-semibold rounded-md text-indigo-700 focus:outline-none transition ease-in-out duration-150 sm:text-md sm:leading-5"
                      onClick={() =>
                        setUserValue({ showRequestPayoutModal: true })
                      }
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </dd>
                </div>
              </div>

              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 ">
                    Total earned
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    $
                    {isDemo
                      ? "2,500"
                      : (user &&
                          user.stats &&
                          new Intl.NumberFormat("en-IN", {}).format(
                            user.stats.earned
                          )) ||
                        0}
                  </dd>
                </div>
              </div>
            </dl>
            {user &&
              user.pendingPayout &&
              user.pendingPayout.status === "pending" && (
                <p className="mt-1 text-sm text-green-500">
                  🚀 A transfer of ${user.pendingPayout.amount} is on its way!
                </p>
              )}
          </div>

          {props.messages.length > 0 ? (
            <ul>
              <li>
                {props.messages.map(
                  (item, index) =>
                    !item.refund && (
                      <Item
                        key={index}
                        id={index}
                        data={item}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        user={user}
                        isDemo={isDemo}
                        archiveMsg={archiveMsg}
                        unarchiveMsg={unarchiveMsg}
                      />
                    )
                )}
              </li>
            </ul>
          ) : (
            <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
              <img
                className="w-2/5 my-5 mx-auto rounded-lg"
                src="/assets/no-message.svg"
                alt="success"
              />
              <p className="max-w-xl mt-5 mx-auto text-center text-base leading-7 text-gray-500">
                {translate.noMessage}
              </p>
            </div>
          )}
        </div>
      );

    case "pending":
      const pendingMessages = props.messages.filter(
        (x) => !x.reply && !x.refund
      );
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
          {pendingMessages.length > 0 ? (
            <ul>
              <li>
                {pendingMessages.map((item, index) => (
                  <Item
                    key={index}
                    id={index}
                    data={item}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    user={user}
                    isDemo={isDemo}
                    archiveMsg={archiveMsg}
                    unarchiveMsg={unarchiveMsg}
                  />
                ))}
              </li>
            </ul>
          ) : (
            <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
              <img
                className="w-2/5 my-5 mx-auto rounded-lg"
                src="/assets/no-message.svg"
                alt="success"
              />
              <p className="max-w-xl mt-5 mx-auto text-center text-base leading-7 text-gray-500">
                No pending message
              </p>
            </div>
          )}
        </div>
      );

    case "replied":
      const repliedMessages = props.messages.filter((x) => x.reply);
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
          {repliedMessages.length > 0 ? (
            <ul>
              <li>
                {repliedMessages.map((item, index) => (
                  <Item
                    key={index}
                    id={index}
                    data={item}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    user={user}
                    isDemo={isDemo}
                    archiveMsg={archiveMsg}
                    unarchiveMsg={unarchiveMsg}
                  />
                ))}
              </li>
            </ul>
          ) : (
            <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
              <img
                className="w-2/5 my-5 mx-auto rounded-lg"
                src="/assets/no-message.svg"
                alt="success"
              />
              <p className="max-w-xl mt-5 mx-auto text-center text-base leading-7 text-gray-500">
                No replied message
              </p>
            </div>
          )}
        </div>
      );

    case "noAnswer":
      const noAnswerMessages = props.messages.filter((x) => x.refund);
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
          {noAnswerMessages.length > 0 ? (
            <ul>
              <li>
                {noAnswerMessages.map((item, index) => (
                  <Item
                    key={index}
                    id={index}
                    data={item}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    user={user}
                    isDemo={isDemo}
                    archiveMsg={archiveMsg}
                    unarchiveMsg={unarchiveMsg}
                  />
                ))}
              </li>
            </ul>
          ) : (
            <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
              <img
                className="w-2/5 my-5 mx-auto rounded-lg"
                src="/assets/no-message.svg"
                alt="success"
              />
              <p className="max-w-xl mt-5 mx-auto text-center text-base leading-7 text-gray-500">
                No message
              </p>
            </div>
          )}
        </div>
      );

    case "archived":
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
          {props.archived.length > 0 ? (
            <ul>
              <li>
                {props.archived.map((item, index) => (
                  <Item
                    key={index}
                    id={index}
                    data={item}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    user={user}
                    isDemo={isDemo}
                    isArchived={true}
                    archiveMsg={archiveMsg}
                    unarchiveMsg={unarchiveMsg}
                  />
                ))}
              </li>
            </ul>
          ) : (
            <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
              <img
                className="w-2/5 my-5 mx-auto rounded-lg"
                src="/assets/no-message.svg"
                alt="success"
              />
              <p className="max-w-xl mt-5 mx-auto text-center text-base leading-7 text-gray-500">
                {translate.noArchive}
              </p>
            </div>
          )}
        </div>
      );

    default:
      return (
        <div className="bg-white overflow-hidden my-10 mx-4 col-span-8 md:w-3/4">
          <ul>
            <li>
              {props.messages.map((item, index) => (
                <Item
                  key={index}
                  id={index}
                  data={item}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  user={user}
                  archiveMsg={archiveMsg}
                  unarchiveMsg={unarchiveMsg}
                />
              ))}
            </li>
          </ul>
        </div>
      );
  }
}

export default Messages;
