import React from "react";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import PulseLoader from "react-spinners/PulseLoader";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Create your account",
    subtitle:
      "You need to have an approved request to sign up. Haven't sent your request yet?",
    requestToJoin: "Request to join",
    backHome: "Back to homepage",
    createAccount: "Create my account",
    email: "Email address",
    password: "Password",
    confirmPassword: "Confirm password",
    errorMsg: "Invalid email or password",
  },
  sp: {
    //Spanish
    title: "Ingresar a tu cuenta",
    subtitle:
      "Debes tener una solicitud aprobada para registrarte. ¿Aún no has enviado tu solicitud?",
    requestToJoin: "Solicita para unirte",
    backHome: "Volver a la página de inicio",
    createAccount: "Crear mi cuenta",
    email: "Correo electrónico",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    errorMsg: "Correo electrónico o contraseña no válidos",
  },
});

var unsubscribe;

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      emailInvalid: false,
      emailExists: false,
      passwordWeak: false,
      passwordUnmatch: false,
      otherIssue: false,
      noRequestFound: false,
      requestNotYetApproved: false,
      errorMsg: "",
    };
  }

  componentDidMount = async () => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.props.history.push("/profile");
      }
    });
  };

  componentWillUnmount = async () => {
    unsubscribe && unsubscribe();
  };

  onSocialAuth = async (social) => {
    var provider;

    if (social === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    if (social === "twitter") {
      provider = new firebase.auth.TwitterAuthProvider();
    }
    if (social === "github") {
      provider = new firebase.auth.GithubAuthProvider();
    }

    try {
      const response = await firebase.auth().signInWithPopup(provider);

      if (response.additionalUserInfo.isNewUser) {
        const user = response.user;
        var userAvatar = user.photoURL;

        if (social === "twitter") {
          userAvatar = user.photoURL.replace("_normal", ""); // get original size twitter avatar
        }

        const userObj = {
          avatar: userAvatar || "",
          email: user.email || "",
          uid: user.uid,
          name: user.name ? user.name : user.email.split("@")[0],
          username: "",
          signUpVia: social,
          notification: {
            newMessage: true,
            newsletter: true,
          },
          createdAt: Date.now(),
        };

        await firebase.database().ref("users").child(user.uid).set(userObj);
      }
      this.props.history.push("/pricing");
    } catch (error) {
      // Handle Errors here.
      var errorCode = error.code || "";
      // The email of the user's account used.
      var email = error.email || "";
      await firebase.database().ref("errors").push({
        where: "onSocialAuth",
        error: error,
        errorCode: errorCode,
        email: email,
      });
    }
  };

  onSignup = async (e) => {
    e.preventDefault();

    const { email, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      this.setState({
        emailInvalid: false,
        emailExists: false,
        passwordWeak: false,
        otherIssue: false,
        passwordUnmatch: true,
        errorMsg: "Passwords do not match.",
      });
      return;
    }

    this.setState({ isLoading: true });

    try {
      const requestRef = await firebase
        .database()
        .ref("request-to-join")
        .orderByChild("email")
        .equalTo(email)
        .once("value");
      if (!requestRef.val()) {
        this.setState({
          noRequestFound: true,
          errorMsg: "You need to have an approved application to sign up.",
          isLoading: false,
        });
        return;
      } else {
        if (Object.values(requestRef.val())[0].status !== "success") {
          this.setState({
            requestNotYetApproved: true,
            errorMsg: "You need to have an approved application to sign up.",
            isLoading: false,
          });
          return;
        }
      }

      const userRequest = Object.values(requestRef.val())[0];

      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      if (response.additionalUserInfo.isNewUser) {
        const userUid = response.user.uid;
        const userObject = {
          name: userRequest.firstName + " " + userRequest.lastName,
          username: "",
          avatar:
            "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png",
          email: email,
          uid: userUid,
          createdAt: Date.now(),
          signUpVia: "email",
          website: userRequest.socialLink,
          notification: {
            newMessage: true,
            newsletter: true,
          },
        };

        await firebase.database().ref("users").child(userUid).set(userObject);
      }

      this.props.history.push("/profile");

      this.setState({
        email: "",
        password: "",
        confirmPassword: "",
        isLoading: false,
      });
    } catch (error) {
      switch (error.code) {
        case "auth/weak-password":
          this.setState({
            emailInvalid: false,
            emailExists: false,
            passwordUnmatch: false,
            otherIssue: false,
            passwordWeak: true,
            errorMsg: "The password must be at least 6 characters long.",
          });
          break;
        case "auth/invalid-email":
          this.setState({
            emailExists: false,
            passwordWeak: false,
            passwordUnmatch: false,
            otherIssue: false,
            emailInvalid: true,
            errorMsg: "The email address is badly formatted.",
          });
          break;
        case "auth/email-already-in-use":
          this.setState({
            emailInvalid: false,
            passwordWeak: false,
            passwordUnmatch: false,
            otherIssue: false,
            emailExists: true,
            errorMsg: "The email address is already in use.",
          });
          break;
        default:
          this.setState({
            emailInvalid: false,
            emailExists: false,
            passwordWeak: false,
            passwordUnmatch: false,
            otherIssue: true,
            errorMsg: "Sorry something bad happened. Please retry.",
          });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    translate.setLanguage("en");

    const { email, password, confirmPassword, isLoading } = this.state;
    const {
      emailInvalid,
      emailExists,
      passwordWeak,
      passwordUnmatch,
      otherIssue,
      noRequestFound,
      requestNotYetApproved,
      errorMsg,
    } = this.state;

    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="h-12 w-auto mx-auto"
            src="/assets/influenswer.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            {translate.title}
          </h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            {translate.subtitle}{" "}
            <a
              href="/request"
              className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              {translate.requestToJoin} &rarr;
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {translate.email}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </div>
                {(emailExists || emailInvalid) && (
                  <p className="mt-1 text-sm text-red-500">{errorMsg}</p>
                )}
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {translate.password}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                </div>
                {passwordWeak && (
                  <p className="mt-1 text-sm text-red-500">{errorMsg}</p>
                )}
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {translate.confirmPassword}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={confirmPassword}
                    onChange={this.handleInputChange}
                  />
                </div>
                {(passwordUnmatch ||
                  otherIssue ||
                  requestNotYetApproved ||
                  noRequestFound) && (
                  <p className="mt-1 text-sm text-red-500">{errorMsg}</p>
                )}
              </div>
            </form>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  onClick={this.onSignup}
                >
                  {isLoading ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                  ) : (
                    `${translate.createAccount}`
                  )}
                </button>
              </span>
            </div>

            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm leading-5">
                  <span className="px-2 bg-white text-gray-500">
                    Or sign up with
                  </span>
                </div>
              </div>
              <div className="mt-1 grid grid-cols-2 gap-3">
                <div>
                  <span className="w-full inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                      aria-label="Sign in with Google"
                      onClick={() => this.onSocialAuth("google")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="20px"
                        height="20px"
                      >
                        <path
                          fill="#FFC107"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                        <path
                          fill="#FF3D00"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        />
                        <path
                          fill="#4CAF50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        />
                        <path
                          fill="#1976D2"
                          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                      </svg>
                    </button>
                  </span>
                </div>

                <div>
                  <span className="w-full inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                      aria-label="Sign in with Twitter"
                      onClick={() => this.onSocialAuth("twitter")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="20px"
                        height="20px"
                      >
                        <linearGradient
                          id="_osn9zIN2f6RhTsY8WhY4a"
                          x1="10.341"
                          x2="40.798"
                          y1="8.312"
                          y2="38.769"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0" stopColor="#2aa4f4" />
                          <stop offset="1" stopColor="#007ad9" />
                        </linearGradient>
                        <path
                          fill="url(#_osn9zIN2f6RhTsY8WhY4a)"
                          d="M46.105,11.02c-1.551,0.687-3.219,1.145-4.979,1.362c1.789-1.062,3.166-2.756,3.812-4.758	c-1.674,0.981-3.529,1.702-5.502,2.082C37.86,8.036,35.612,7,33.122,7c-4.783,0-8.661,3.843-8.661,8.582	c0,0.671,0.079,1.324,0.226,1.958c-7.196-0.361-13.579-3.782-17.849-8.974c-0.75,1.269-1.172,2.754-1.172,4.322	c0,2.979,1.525,5.602,3.851,7.147c-1.42-0.043-2.756-0.438-3.926-1.072c0,0.026,0,0.064,0,0.101c0,4.163,2.986,7.63,6.944,8.419	c-0.723,0.198-1.488,0.308-2.276,0.308c-0.559,0-1.104-0.063-1.632-0.158c1.102,3.402,4.299,5.889,8.087,5.963	c-2.964,2.298-6.697,3.674-10.756,3.674c-0.701,0-1.387-0.04-2.065-0.122C7.73,39.577,12.283,41,17.171,41	c15.927,0,24.641-13.079,24.641-24.426c0-0.372-0.012-0.742-0.029-1.108C43.483,14.265,44.948,12.751,46.105,11.02"
                        />
                      </svg>
                    </button>
                  </span>
                </div>
              </div>{" "}
            </div> */}

            <div className="mt-6 flex items-center justify-between">
              <div className="flex items-center text-sm leading-5">
                <a
                  href="/"
                  className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
                >
                  ← {translate.backHome}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
