import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import Sidebar from "./Sidebar";
import Messages from "./Messages";
import "./Dashboard.scss";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import RecordAudioModal from "../../components/Modals/RecordAudioModal";
import RecordVideoModal from "../../components/Modals/RecordVideoModal";
import NoAnswerModal from "../../components/Modals/NoAnswerModal";
import RequestPayoutModal from "../../components/Modals/RequestPayoutModal";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "All messages",
    demoSubtitle: "It's the demo page. Videos are muted.",
  },
  sp: {
    //Spanish
    title: "Todos los mensajes",
    demoSubtitle: "Es la página de demostración. Los videos están silenciados.",
  },
});

var unsubscribe = null;
var userListener;
var messagesListener;
var archivedListener;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isOpen: false,
      currentUser: null,
      tab: "all",
      page: 1,
      messages: [],
      archived: [],
      demoMessages: [
        {
          createdAt: Date.now() - 30000, // 30s ago
          playbackIds: [{ id: "1w4fWBsuW3bL41wq32Di4eXgVyqcXQrDOgZRLCkEbAM" }],
          downloadURL: "",
          sentBy: { name: "Emily Cook", email: "emily@cook.com" },
          type: "Video",
        },
        {
          createdAt: Date.now() - 300000, // 5 mins ago
          playbackIds: [
            { id: "gha5tr4BCAaIWLy0102zJQJl00oGBbFoqvlqrZnDc7YrM4" },
          ],
          downloadURL: "",
          sentBy: { name: "Emma Perry", email: "emma@perry.com" },
          type: "Video",
          reply: {},
        },
      ],
      demoArchived: [],
    };
  }

  componentDidMount() {
    this.props.isDemo
      ? setTimeout(() => this.setState({ isLoading: false }), 1000)
      : (unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            userListener = firebase
              .database()
              .ref("users")
              .child(user.uid)
              .on("value", (snapshot) => {
                this.setState({ currentUser: snapshot.val() });
              });

            messagesListener = firebase
              .database()
              .ref("messages")
              .child(user.uid)
              .orderByChild("createdAt")
              .on("value", (snapshot) => {
                var messagesArray = [];

                snapshot.forEach((message) => {
                  messagesArray.push(message.val());
                });

                this.setState({
                  messages: messagesArray.reverse(),
                });
              });

            archivedListener = firebase
              .database()
              .ref("archived")
              .child(user.uid)
              .orderByChild("createdAt")
              .on("value", (snapshot) => {
                var archivedArray = [];

                snapshot.forEach((message) => {
                  archivedArray.push(message.val());
                });

                this.setState({
                  archived: archivedArray.reverse(),
                });
              });

            this.setState({
              isLoading: false,
            });
          } else {
            this.props.history.push("/");
          }
        }));
  }

  componentWillUnmount = () => {
    const { currentUser } = this.state;
    unsubscribe && unsubscribe();

    if (currentUser) {
      firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .off("value", userListener);

      firebase
        .database()
        .ref("messages")
        .child(currentUser.uid)
        .off("value", messagesListener);

      firebase
        .database()
        .ref("archived")
        .child(currentUser.uid)
        .off("value", archivedListener);
    }
  };

  onChangeTab = (tab) => {
    this.setState({ tab: tab, isOpen: false });
  };

  setIsOpen = (status) => {
    this.setState({ isOpen: status });
  };

  archiveMsg = (message) => {
    var archivedArray = this.state.demoArchived;
    archivedArray.push(message);
    this.setState({ demoArchived: archivedArray });
  };

  unarchiveMsg = (message) => {
    var archivedArray = this.state.demoArchived;
    var index = archivedArray.indexOf(message);
    archivedArray.splice(index, 1);
    this.setState({ demoArchived: archivedArray });
  };

  render() {
    translate.setLanguage("en");

    const {
      messages,
      archived,
      tab,
      currentUser,
      isOpen,
      demoMessages,
      demoArchived,
    } = this.state;
    const { isDemo, user, setUserValue } = this.props;
    const {
      showAudioModal,
      showVideoModal,
      showNoAnswerModal,
      showRequestPayoutModal,
    } = user;

    return (
      <div>
        {showAudioModal && <RecordAudioModal />}
        {showVideoModal && <RecordVideoModal />}
        {showNoAnswerModal && <NoAnswerModal />}
        {showRequestPayoutModal && <RequestPayoutModal isDemo={isDemo} />}
        <header>
          <div className="max-w-7xl text-center mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-700">
              {translate.title}
            </h1>
            {isDemo && (
              <p className="mt-2 text-md leading-5 text-gray-500">
                {translate.demoSubtitle}
              </p>
            )}
          </div>
        </header>
        <div className="container mx-auto dashboard-container">
          <div className="grid md:grid-cols-12 sm:grid-cols-1">
            <Sidebar
              messages={isDemo ? demoMessages : messages}
              archived={isDemo ? demoArchived : archived}
              tab={tab}
              onChangeTab={this.onChangeTab}
              isOpen={isOpen}
            />
            <Messages
              messages={isDemo ? demoMessages : messages}
              archived={isDemo ? demoArchived : archived}
              tab={tab}
              user={currentUser}
              isOpen={isOpen}
              setIsOpen={this.setIsOpen}
              archiveMsg={this.archiveMsg}
              unarchiveMsg={this.unarchiveMsg}
              isDemo={isDemo}
              setUserValue={setUserValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
