import React from "react";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import PulseLoader from "react-spinners/PulseLoader";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Request to join us",
    subtitle: "Request got approved? 🎉",
    signUp: "Sign up",
    firstName: "First name",
    lastName: "Last name",
    email: "Email address",
    socialLink: "Social media link",
    sentRequet: "Send request",
    backHome: "Back to homepage",
    noFirstname: "First name is missing",
    noLastname: "Last name is missing",
    noEmail: "Email is missing",
    invalidEmail: "Invalid email address",
    duplicateRequest: "We already received your request for this email",
    noSocialLink: "Social link is missing",
    requestIsSent: "Your request is sent",
    followUpSoon: "You should receive an update soon!",
  },
  sp: {
    //Spanish
    title: "Solicitar para unirse.",
    subtitle: "¿Tu solicitud fue aprobada? 🎉",
    signUp: "Regístrate",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    email: "Correo electrónico",
    socialLink: "enlace de redes sociales",
    sentRequet: "Enviar petición",
    backHome: "Volver a la página de inicio",
    noFirstname: "Falta el nombre de pila",
    noLastname: "Falta el apellido",
    noEmail: "Falta el correo electrónico",
    invalidEmail: "Dirección de correo electrónico no válida",
    duplicateRequest: "Ya recibimos su solicitud para este correo electrónico",
    noSocialLink: "Falta el enlace a las redes sociales",
    requestIsSent: "Su solicitud es enviada",
    followUpSoon: "¡Debería recibir una actualización pronto!",
  },
});

var unsubscribe;

class RequestInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      socialLink: "",
      isLoading: false,
      missingFirstName: false,
      missingLastName: false,
      missingSocialLink: false,
      missingEmail: false,
      inValidEmail: false,
      duplicateRequest: false,
      sentSuccess: false,
    };
  }

  componentDidMount = async () => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.props.history.push("/profile");
      }
    });
  };

  componentWillUnmount = async () => {
    unsubscribe && unsubscribe();
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSendRequest = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, socialLink } = this.state;

    this.setState({
      missingFirstName: false,
      missingLastName: false,
      missingSocialLink: false,
      missingEmail: false,
      duplicateRequest: false,
    });

    if (firstName === "") {
      this.setState({ missingFirstName: true });
      return;
    }
    if (lastName === "") {
      this.setState({ missingLastName: true });
      return;
    }
    if (email === "") {
      this.setState({ missingEmail: true });
      return;
    }
    if (socialLink === "") {
      this.setState({ missingSocialLink: true });
      return;
    }

    if (this.validateEmail(email) === false) {
      // not a valid email address
      this.setState({ inValidEmail: true });
    }

    this.setState({ isLoading: true });
    try {
      const requestRef = await firebase
        .database()
        .ref("request-to-join")
        .orderByChild("email")
        .equalTo(email)
        .once("value");

      if (requestRef.val()) {
        // request already sent
        this.setState({ duplicateRequest: true, isLoading: false });
        return;
      }

      await firebase.database().ref("request-to-join").push({
        firstName: firstName,
        lastName: lastName,
        email: email,
        socialLink: socialLink,
        status: "pending",
      });
    } catch (error) {
      console.log("error", error);
    }
    this.setState({
      isLoading: false,
      sentSuccess: true,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    translate.setLanguage("en");

    const {
      firstName,
      lastName,
      email,
      socialLink,
      isLoading,
      duplicateRequest,
      sentSuccess,
    } = this.state;

    const {
      missingFirstName,
      missingLastName,
      missingEmail,
      inValidEmail,
      missingSocialLink,
    } = this.state;

    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="h-12 w-auto mx-auto"
            src="/assets/influenswer.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            {translate.title}
          </h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            {translate.subtitle}{" "}
            <a
              href="/signup"
              className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              {translate.signUp} &rarr;
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlhtmlFor="firstName"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                {translate.firstName}
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="firstName"
                  name="firstName"
                  required
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={firstName}
                  onChange={this.handleInputChange}
                />
              </div>
              {missingFirstName && (
                <p className="mt-1 text-sm text-red-500">
                  {translate.noFirstname}
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                {translate.lastName}
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="lastName"
                  name="lastName"
                  required
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={lastName}
                  onChange={this.handleInputChange}
                />
              </div>
              {missingLastName && (
                <p className="mt-1 text-sm text-red-500">
                  {translate.noLastname}
                </p>
              )}
            </div>
            <div className="sm:col-span-6">
              <label
                htmlhtmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                {translate.email}
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={email}
                  onChange={this.handleInputChange}
                />
              </div>
              {missingEmail && (
                <p className="mt-1 text-sm text-red-500">{translate.noEmail}</p>
              )}
              {inValidEmail && (
                <p className="mt-1 text-sm text-red-500">
                  {translate.invalidEmail}
                </p>
              )}
              {duplicateRequest && (
                <p className="mt-1 text-sm text-red-500">
                  {translate.duplicateRequest}
                </p>
              )}
            </div>

            <div className="sm:col-span-6">
              <label
                htmlhtmlFor="socialLink"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                {translate.socialLink}
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  https://
                </span>
                <input
                  id="socialLink"
                  name="socialLink"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-none rounded-r-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  value={socialLink}
                  onChange={this.handleInputChange}
                />
              </div>
              {missingSocialLink && (
                <p className="mt-1 text-sm text-red-500">
                  {translate.noSocialLink}
                </p>
              )}
            </div>

            <div className="mt-2 sm:col-span-6">
              <span className="block w-full rounded-md shadow-sm">
                {sentSuccess ? (
                  <>
                    <button className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-400 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                      {translate.requestIsSent} 👍
                    </button>
                  </>
                ) : (
                  <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    onClick={this.onSendRequest}
                  >
                    {isLoading ? (
                      <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                    ) : (
                      `${translate.sentRequet}`
                    )}
                  </button>
                )}
              </span>
              {sentSuccess && (
                <p className="mt-1 text-sm text-green-500">
                  {translate.followUpSoon}
                </p>
              )}
            </div>

            <div className="mt-2 flex items-center justify-between sm:col-span-6">
              <div className="flex items-center text-sm leading-5">
                <a
                  href="/"
                  className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
                >
                  ← {translate.backHome}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestInvite;
