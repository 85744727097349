import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import VideoPlayer from "../Utils/VideoPlayer/VideoPlayer";

class PlayVideoModal extends React.Component {
  onCloseModals = () => {
    this.props.setUserValue({
      showPlayVideoModal: false,
    });
  };

  render() {
    const videoPoster =
      "https://image.mux.com/k01DXn7nsiFixOuaIBNqMsPtDOgzMq1ieKHTdRWmZSHU/thumbnail.png";
    const videoURL =
      "https://stream.mux.com/k01DXn7nsiFixOuaIBNqMsPtDOgzMq1ieKHTdRWmZSHU.m3u8";

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: true,
      controls: true,
      fluid: true,
      // aspectRatio: "16:9"
    };

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => this.onCloseModals()}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-4/5 lg:w-3/5"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <VideoPlayer
              {...videoJsOptions}
              poster={videoPoster}
              src={videoURL}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlayVideoModal);
