import React from "react";
import { formatDistance } from "date-fns";
import VideoPlayer from "../../components/Utils/VideoPlayer/VideoPlayer";

class VideoMessage extends React.Component {
  render() {
    const { message } = this.props;

    const messageSent = message.sentBy;
    const messageReplied = message.repliedBy;

    const videoPosterSent =
      "https://image.mux.com/" +
      messageSent.message.playbackIds[0].id +
      "/thumbnail.png";
    const videoURLSent =
      "https://stream.mux.com/" +
      messageSent.message.playbackIds[0].id +
      ".m3u8";

    if (messageReplied) {
      var videoPosterReplied =
        "https://image.mux.com/" +
        messageReplied.message.playbackIds[0].id +
        "/thumbnail.png";
      var videoURLReplied =
        "https://stream.mux.com/" +
        messageReplied.message.playbackIds[0].id +
        ".m3u8";
    }

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: false,
      controls: true,
      fluid: true,
      // aspectRatio: "16:9",
    };

    return (
      <div
        className={`bg-gray-50 flex flex-col ${
          messageReplied ? "justify-start" : "justify-center"
        } py-12 sm:px-6 lg:px-8 video-page-container`}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="py-5 border-none">
            <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
              <h3 className="ml-2 mt-2 text-lg leading-6 font-semibold text-gray-600">
                {messageSent.user.name !== ""
                  ? "Sent by " + messageSent.user.name
                  : "Message was received"}
              </h3>
              <p className="ml-2 mt-1 text-sm leading-5 text-gray-500 truncate">
                {formatDistance(messageSent.message.createdAt, Date.now())} ago
              </p>
            </div>
          </div>

          <div className="bg-white overflow-hidden sm:rounded-lg">
            <div>
              <VideoPlayer
                {...videoJsOptions}
                poster={videoPosterSent}
                src={videoURLSent}
              />
            </div>
          </div>

          {messageSent.message.additionalMessage &&
            messageSent.message.additionalMessage !== "" && (
              <div className="mt-4 text-sm font-normal text-left">
                <p className="text-gray-400 font-semibold">
                  ADDITIONAL MESSAGE
                </p>
                <p>{messageSent.message.additionalMessage}</p>
              </div>
            )}

          {messageReplied && (
            <>
              <div className="py-5 border-none">
                <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                  <h3 className="ml-2 mt-2 text-lg leading-6 font-semibold text-gray-600">
                    <i className="fas fa-reply mr-2"></i>Replied by{" "}
                    {messageReplied.user.name}
                  </h3>
                  <p className="ml-2 mt-1 text-sm leading-5 text-gray-500 truncate">
                    {formatDistance(
                      messageReplied.message.createdAt,
                      Date.now()
                    )}{" "}
                    ago
                  </p>
                </div>
              </div>

              <div className="bg-white overflow-hidden sm:rounded-lg">
                <div>
                  <VideoPlayer
                    {...videoJsOptions}
                    poster={videoPosterReplied}
                    src={videoURLReplied}
                  />
                </div>
              </div>

              {messageReplied.message.additionalMessage &&
                messageReplied.message.additionalMessage !== "" && (
                  <div className="mt-4 text-sm font-normal text-left">
                    <p className="text-gray-400 font-semibold">
                      ADDITIONAL MESSAGE
                    </p>
                    <p>{messageReplied.message.additionalMessage}</p>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default VideoMessage;
