import React from "react";

class LinkRenderer extends React.Component {
  render() {
    return (
      <a
        href={this.props.href}
        target="_blank"
        className="text-indigo-600 hover:text-indigo-500"
      >
        {this.props.children}
      </a>
    );
  }
}

export default LinkRenderer;
