import React from "react";
import { formatDistance } from "date-fns";
import ReactAudioPlayer from "react-audio-player";

class AudioMessage extends React.Component {
  render() {
    const { message } = this.props;

    const messageSent = message.sentBy;
    const messageReplied = message.repliedBy;

    return (
      <div
        className={`bg-gray-50 flex flex-col ${
          messageReplied ? "justify-start" : "justify-center"
        } py-12 sm:px-6 lg:px-8 video-page-container`}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="py-5">
            <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
              <h3 className="ml-2 mt-2 text-lg leading-6 font-semibold text-gray-600">
                {messageSent.user.name !== ""
                  ? "Sent by " + messageSent.user.name
                  : "Message was received"}
              </h3>
              <p className="ml-2 mt-1 text-sm leading-5 text-gray-500 truncate">
                {formatDistance(messageSent.message.createdAt, Date.now())} ago
              </p>
            </div>
          </div>

          <div className=" overflow-hidden sm:rounded-lg">
            <div>
              <ReactAudioPlayer
                src={messageSent.message.downloadURL}
                controls
                autoPlay={false}
                controlsList="nodownload"
              />
            </div>
          </div>

          {messageReplied && (
            <>
              <div className="py-5 mt-4">
                <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                  <h3 className="ml-2 mt-2 text-lg leading-6 font-semibold text-gray-600">
                    <i className="fas fa-reply mr-2"></i>Replied by{" "}
                    {messageReplied.user.name}
                  </h3>
                  <p className="ml-2 mt-1 text-sm leading-5 text-gray-500 truncate">
                    {formatDistance(
                      messageReplied.message.createdAt,
                      Date.now()
                    )}{" "}
                    ago
                  </p>
                </div>
              </div>

              <div className=" overflow-hidden sm:rounded-lg">
                <div>
                  <ReactAudioPlayer
                    src={messageReplied.message.downloadURL}
                    controls
                    autoPlay={false}
                    controlsList="nodownload"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default AudioMessage;
