import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import PulseLoader from "react-spinners/PulseLoader";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Reset your password",
    email: "Email address",
    resetBtn: "Reset password",
    resetSuccess: "Reset email sent!",
    backToLogin: "Back to login page",
  },
  sp: {
    //Spanish
    title: "Restablecer su contraseña",
    email: "Dirección de correo electrónico",
    resetBtn: "Restablecer la contraseña",
    resetSuccess: "Se envía el correo electrónico de restablecimiento",
    backToLogin: "Volver a la página de inicio de sesión",
  },
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      notificationSent: false,
      isLoading: false,
    };
  }

  onReset = async (e) => {
    e.preventDefault();

    const { email } = this.state;

    this.setState({ isLoading: true, notificationSent: false });

    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      this.setState({ isLoading: false });
    }

    this.setState({ notificationSent: true, isLoading: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    translate.setLanguage("en");

    const { email, isLoading, notificationSent } = this.state;

    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="h-12 w-auto mx-auto"
            src="/assets/influenswer.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            {translate.title}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {translate.email}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </div>
                {notificationSent && (
                  <p className="mt-1 text-sm text-gray-500">
                    {translate.resetSuccess}
                  </p>
                )}
              </div>
            </form>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  onClick={this.onReset}
                >
                  {isLoading ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                  ) : (
                    `${translate.resetBtn}`
                  )}
                </button>
              </span>
            </div>

            <div className="mt-6 flex items-center justify-between">
              <div className="flex items-center text-sm leading-5">
                <a
                  href="/login"
                  className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
                >
                  ← {translate.backToLogin}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
