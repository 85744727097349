import React, { Component } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./VideoPlayer.css";

window.videojs = videojs;
// require("videojs-contrib-hls/dist/videojs-contrib-hls.js");

class Player extends Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // console.log("onPlayerReady", this);
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div data-vjs-player className="video-container">
        <video
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-big-play-centered "
          poster={this.props.poster}
        >
          <source src={this.props.src} type="application/x-mpegURL" />
        </video>
      </div>
    );
  }
}

export default Player;
