import React, { useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

function SupportModal(props) {
  const [followerName, setFollowerName] = useState("");
  const [followerEmail, setFollowerEmail] = useState("");
  const [noName, setNoName] = useState(false);
  const [noEmail, setNoEmail] = useState(false);

  const onCloseModals = () => {
    props.setUserValue({
      showSupportModal: false,
      showAudioModal: false,
      showVideoModal: false,
      isDemo: false,
      videoLimit: null,
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (event.target.id === "followerName") {
      setFollowerName(value);
    } else {
      // followerEmail
      setFollowerEmail(value);
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => onCloseModals()}
        >
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg
                className="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-semibold text-gray-600"
                id="modal-headline"
              >
                Your monthly contribution
              </h3>
              <div className="mt-2">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 rounded-l-lg border border-gray-300 bg-white text-sm leading-5 font-semibold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    $1/month
                  </button>
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-100 text-sm leading-5 font-semibold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    $2/month
                  </button>
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-lg border border-gray-300 bg-white text-sm leading-5 font-semibold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    $5/month
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <div>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="followerName"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="Your name"
                  value={followerName}
                  onChange={handleInputChange}
                />
              </div>
              {noName && (
                <p className="mt-1 text-sm text-red-500">Name is required</p>
              )}
            </div>
            <div className="mt-2">
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="followerEmail"
                  className="form-input block w-full sm:text-sm sm:leading-5"
                  placeholder="Your email"
                  value={followerEmail}
                  onChange={handleInputChange}
                />
              </div>
              {noEmail && (
                <p className="mt-1 text-sm text-red-500">Email is required</p>
              )}
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Support for $2 a month
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(SupportModal);
