import React from "react";
import Dropzone from "react-dropzone";
import { format } from "date-fns";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/functions";
import "./Billing.css";
import DisconnectModal from "./DisconnectModal";
import RequestPayoutModal from "../../components/Modals/RequestPayoutModal";
import StripeSupportedCountriesModal from "../../components/Modals/StripeSupportedCountriesModal";
import Localizedtranslate from "react-localization";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

var unsubscribe;
var currentUserListener;

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Payment settings",
    subtitle: "You can choose Stripe or PayPal for simplified billing.",
    connectedTitle: "Your Stripe account is connected 🎉",
    connectSubtitle:
      "Stripe lets you get paid on time and to keep your personal bank and details secure. Click Connect to set up your payments on Stripe.",
    toConnectTitle: "Connect Stripe",
    myDashboard: "My dashboard",
    connect: "Connect",
  },
  sp: {
    //Spanish
    title: "Ingresar a tu cuenta",
    subtitle: "Puede elegir Stripe o PayPal para una facturación simplificada.",
    connectedTitle: "Tu cuenta de Stripe está conectada 🎉",
    connectSubtitle:
      "Usamos Stripe para asegurarnos de que te paguen a tiempo y para mantener seguros tus datos personales y de pago seguros. Haga clic en Conectar para configurar sus pagos en Stripe.",
    toConnectTitle: "Conecte Stripe",
    myDashboard: "Mi tablero",
    connect: "Conectar",
  },
});

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentUser: null,
      connectBtnLoading: false,
      portalBtnLoading: false,
      dashboardBtnLoading: false,
      showDisconnectModal: false,
      isStripe: true,
      paypalEmail: "",
      paypalSaved: false,
      inValidEmail: false,
      paymentMethod: "stripe",
    };
  }

  componentDidMount() {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              currentUser: snapshot.val(),
              paymentMethod: snapshot.val().paymentMethod || "stripe",
              isStripe:
                !snapshot.val().paymentMethod ||
                snapshot.val().paymentMethod === "stripe",
              paypalEmail:
                snapshot.val().paymentMethod === "paypal" &&
                snapshot.val().paypal
                  ? snapshot.val().paypal.email
                  : "",
              isLoading: false,
            });
          });
      } else {
        this.props.history.push("/");
      }
    });
  }

  componentWillUnmount = async () => {
    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe();
  };

  accessStripeDashboard = async () => {
    const { currentUser } = this.state;

    this.setState({ dashboardBtnLoading: true });

    var stripeAccessDashboard = await firebase
      .functions()
      .httpsCallable("stripeAccessDashboard");
    stripeAccessDashboard({
      accountId: currentUser.stripe.accountId,
    })
      .then(async (result) => {
        this.setState({ dashboardBtnLoading: false });
        window.open(result.data.url);
      })
      .catch((error) => {
        this.setState({ dashboardBtnLoading: false });
      });
  };

  accessStripePortal = async () => {
    const { currentUser } = this.state;

    this.setState({ portalBtnLoading: true });

    var stripeCreatePortalSession = await firebase
      .functions()
      .httpsCallable("stripeCreatePortalSession");
    stripeCreatePortalSession({
      customerId: currentUser.stripe.id,
    })
      .then(async (result) => {
        this.setState({ portalBtnLoading: false });
        window.open(result.data.url);
      })
      .catch((error) => {
        this.setState({ portalBtnLoading: false });
      });
  };

  stripeConnectOnboarding = async () => {
    this.setState({ connectBtnLoading: true });
    var stripeConnectOnboarding = await firebase
      .functions()
      .httpsCallable("stripeConnectOnboarding");
    stripeConnectOnboarding({ userUid: this.state.currentUser.uid })
      .then(async (result) => {
        this.setState({ connectBtnLoading: false });
        window.location.replace(result.data.url);
      })
      .catch((error) => {
        this.setState({ connectBtnLoading: false });
      });
  };

  onShowDisconnectModal = () => {
    this.setState({ showDisconnectModal: true });
  };

  onCloseDisconnectModal = () => {
    this.setState({ showDisconnectModal: false });
  };

  onToggleStripe = async () => {
    const { isStripe, currentUser } = this.state;

    this.setState({ isStripe: !isStripe });

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({ paymentMethod: isStripe ? "paypal" : "stripe" });
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.id]: value });
  };

  savePaypalAccount = async () => {
    const { paypalEmail, currentUser } = this.state;

    this.setState({ paypalSaved: false, inValidEmail: false });

    if (this.validateEmail(paypalEmail) === false) {
      // not a valid email address
      this.setState({ inValidEmail: true });
      return;
    }

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({ paypal: { email: paypalEmail } });

    this.setState({
      paypalSaved: true,
    });
  };

  render() {
    translate.setLanguage("en");

    const {
      isLoading,
      connectBtnLoading,
      portalBtnLoading,
      dashboardBtnLoading,
      currentUser,
      showDisconnectModal,
      isStripe,
      paypalEmail,
      paypalSaved,
      inValidEmail,
    } = this.state;

    if (isLoading) {
      return (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen billing-page-container">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      );
    }

    return (
      <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24">
        {showDisconnectModal && (
          <DisconnectModal
            closeModal={this.onCloseDisconnectModal}
            currentUser={currentUser}
          />
        )}

        {this.props.user.showRequestPayoutModal && <RequestPayoutModal />}
        {this.props.user.showStripeSupportedCountriesModal && (
          <StripeSupportedCountriesModal />
        )}
        <form>
          <div>
            <div>
              <div className="bg-white py-5 border-b border-gray-200">
                <h3 className="text-4xl leading-9 font-semibold text-gray-900">
                  {translate.title}
                </h3>
                <p className="mt-2 text-md leading-5 text-gray-500">
                  {translate.subtitle}
                </p>
              </div>

              {/* <div className="bg-white shadow sm:rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Manage Billing
                  </h3>
                  <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>
                      You can go to Stripe Billing Portal to update payment
                      methods, view billing history, change a plan.
                    </p>
                  </div>
                  <div className="mt-5">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5 w-40"
                      onClick={this.accessStripePortal}
                    >
                      {portalBtnLoading ? (
                        <PulseLoader
                          sizeUnit={"px"}
                          size={8}
                          color={"#5850ec"}
                        />
                      ) : (
                        "Access Portal"
                      )}
                    </button>
                  </div>
                </div>
              </div> */}

              <div className="flex items-center space-x-3  mt-5">
                <span id="toggleLabel">
                  <span
                    className={`text-base leading-5 ${
                      isStripe
                        ? "text-indigo-600 font-semibold"
                        : "text-gray-900 font-medium"
                    }`}
                  >
                    Stripe
                  </span>
                </span>
                <span
                  role="checkbox"
                  tabindex="0"
                  aria-checked="true"
                  aria-labelledby="toggleLabel"
                  className={`${
                    isStripe ? "bg-gray-200" : "bg-indigo-500"
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline-indigo`}
                  onClick={this.onToggleStripe}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      isStripe ? "translate-x-0" : "translate-x-5"
                    } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                  ></span>
                </span>
                <span id="toggleLabel">
                  <span
                    className={`text-base leading-5 font-medium ${
                      isStripe
                        ? "text-gray-900 font-medium"
                        : "text-indigo-600 font-semibold"
                    }`}
                  >
                    PayPal
                  </span>
                </span>
              </div>

              {isStripe ? (
                <div className="bg-white shadow sm:rounded-lg mt-5">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {currentUser.stripe &&
                      currentUser.stripe.details_submitted
                        ? translate.connectedTitle
                        : translate.toConnectTitle}
                    </h3>
                    <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                      <p>
                        Stripe currently supports these 👉{" "}
                        <span
                          className="underline cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:underline transition ease-in-out duration-150"
                          onClick={() =>
                            this.props.setUserValue({
                              showStripeSupportedCountriesModal: true,
                            })
                          }
                        >
                          37 countries
                        </span>
                        . Please make sure your country is in the list first.
                        Then click Connect to set up your payments on Stripe.
                      </p>
                    </div>
                    <div className="mt-5">
                      {currentUser.stripe &&
                      currentUser.stripe.details_submitted ? (
                        <>
                          <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5 w-40 mr-2"
                            onClick={this.accessStripeDashboard}
                          >
                            {dashboardBtnLoading ? (
                              <PulseLoader
                                sizeUnit={"px"}
                                size={8}
                                color={"#5850ec"}
                              />
                            ) : (
                              `${translate.myDashboard}`
                            )}
                          </button>
                          {/* <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-orange-700 bg-orange-100 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-orange-200 transition ease-in-out duration-150 sm:text-md sm:leading-5 w-40"
                          onClick={() => this.onShowDisconnectModal()}
                        >
                          <span className="connected">Connected 🎉</span>
                          <span className="disconnect"></span>
                        </button> */}
                        </>
                      ) : (
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5"
                          onClick={this.stripeConnectOnboarding}
                        >
                          {connectBtnLoading ? (
                            <PulseLoader
                              sizeUnit={"px"}
                              size={8}
                              color={"#5850ec"}
                            />
                          ) : (
                            `${translate.connect}`
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white shadow sm:rounded-lg mt-5">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Connect PayPal
                    </h3>
                    <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                      <p>
                        Accept instant payments, direct to your PayPal account.
                      </p>
                    </div>
                    <div className="mt-5 sm:flex sm:items-center">
                      <div className="max-w-xs w-full">
                        <label for="email" className="sr-only">
                          Email
                        </label>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            id="paypalEmail"
                            className="form-input block w-full sm:text-sm sm:leading-5"
                            placeholder="you@example.com"
                            value={paypalEmail}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <span className="mt-3 inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150 sm:text-md sm:leading-5"
                          onClick={this.savePaypalAccount}
                        >
                          Save
                        </button>
                      </span>
                    </div>

                    <p
                      className="mt-1 text-sm text-gray-500"
                      id="email-description"
                    >
                      💡 Enter the email associated with your PayPal account.
                    </p>

                    {paypalSaved && (
                      <p className="mt-1 text-sm text-green-500">
                        ✅ Your PayPal account is saved!
                      </p>
                    )}

                    {inValidEmail && (
                      <p className="mt-1 text-sm text-red-500">
                        Your PayPal account doesn't seem correct :(
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="mt-10">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  Your stats 🎉
                </h3>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 ">
                        Total questions answered
                      </dt>
                      <dd className="mt-1 text-3xl font-semibold text-gray-900">
                        {(currentUser &&
                          currentUser.stats &&
                          new Intl.NumberFormat("en-IN", {}).format(
                            currentUser.stats.questions
                          )) ||
                          0}
                      </dd>
                    </div>
                  </div>

                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 ">
                        Current balance
                      </dt>
                      <dd className="mt-1 flex justify-between text-gray-900">
                        <div className="flex items-baseline text-3xl font-semibold ">
                          $
                          {(currentUser &&
                            currentUser.stats &&
                            new Intl.NumberFormat("en-IN", {}).format(
                              currentUser.stats.balance
                            )) ||
                            0}
                        </div>

                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-1 border border-transparent font-semibold rounded-md text-indigo-700 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo  transition ease-in-out duration-150 sm:text-md sm:leading-5"
                          onClick={() =>
                            this.props.setUserValue({
                              showRequestPayoutModal: true,
                            })
                          }
                        >
                          <svg
                            className="h-8 w-8"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </dd>
                    </div>
                  </div>

                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-sm font-medium text-gray-500 ">
                        Total earned
                      </dt>
                      <dd className="mt-1 text-3xl font-semibold text-gray-900">
                        $
                        {(currentUser &&
                          currentUser.stats &&
                          new Intl.NumberFormat("en-IN", {}).format(
                            currentUser.stats.earned
                          )) ||
                          0}
                      </dd>
                    </div>
                  </div>
                </dl>

                {currentUser.pendingPayout &&
                  currentUser.pendingPayout.status === "pending" && (
                    <p className="mt-1 text-sm text-green-500">
                      You submitted a transfer request for $
                      {currentUser.pendingPayout.amount} on{" "}
                      {format(
                        currentUser.pendingPayout.createdAt,
                        "MM/dd/yyyy"
                      )}
                      . It's on the way! 🚀
                    </p>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Billing);
