import React from "react";
import "./PaymentStatus.css";

class PaymentLater extends React.Component {
  render() {
    return (
      <div className="bg-white payment-success-container">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-none lg:tracking-normal lg:text-6xl">
              Hmm, maybe later
            </p>

            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              You can always come back to choose a plan right for you. Don't
              forget you'll have{" "}
              <span className="font-bold text-gray-700">14-day free trial</span>{" "}
              and{" "}
              <span className="font-bold text-gray-700">
                30-day money-back guarantee
              </span>{" "}
              😉
            </p>

            <img
              className="my-5 mx-auto rounded-lg"
              src="https://media.giphy.com/media/l0HlLZiHatn0BLJde/giphy.gif"
              alt="later"
            />

            <a
              href="/"
              className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
            >
              ← Back to homepage
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentLater;
