import React from "react";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    cancel: "Cancel",
    confirm: "Confirm",
  },
  sp: {
    //Spanish
    cancel: "Cancelar",
    confirm: "Confirmar",
  },
});

class RequestPayoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirming: false,
    };
  }

  onCloseModal = () => {
    this.props.setUserValue({
      showRequestPayoutModal: false,
    });
  };

  onConfirm = async () => {
    const { isDemo, user } = this.props;
    const { message, currentUser } = user;

    if (isDemo) {
      this.onCloseModal();
      return;
    }

    this.setState({ isConfirming: true });

    var paymentAccount;
    if (!currentUser.paymentMethod || currentUser.paymentMethod === "stripe") {
      paymentAccount = currentUser.stripe.accountId;
    } else {
      // paymentMethod === "paypal"
      paymentAccount = currentUser.paypal.email;
    }

    await firebase
      .database()
      .ref("payout-requests")
      .push({
        paymentMethod: currentUser.paymentMethod || "stripe",
        paymentAccount: paymentAccount,
        amount: (currentUser.stats && currentUser.stats.balance) || 0,
        userId: currentUser.uid,
        userName: currentUser.name,
        userEmail: currentUser.email,
      });

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({
        pendingPayout: {
          amount: (currentUser.stats && currentUser.stats.balance) || 0,
          createdAt: Date.now(),
          status: "pending",
        },
      });

    this.setState({ isConfirming: false });
    this.onCloseModal();
  };

  render() {
    translate.setLanguage("en");

    const { isConfirming } = this.state;
    const { isDemo, user } = this.props;
    var currentUser = user.currentUser;

    if (isDemo) {
      currentUser = {
        stats: { questions: 120, balance: 100, earned: 2500 },
        paymentMethod: "paypal",
        paypal: { email: "sample@gmail.com" },
      };
    }

    const zeroBalance =
      !currentUser.stats ||
      (currentUser.stats &&
        (!currentUser.stats.balance || currentUser.stats.balance === 0));

    const alreadyRequested =
      (currentUser.pendingPayout &&
        currentUser.pendingPayout.status === "pending") ||
      false;

    const noPaymentMethod = !(
      (currentUser.stripe && currentUser.stripe.details_submitted) ||
      (currentUser.paymentMethod === "paypal" &&
        currentUser.paypal &&
        currentUser.paypal.email !== "")
    );

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => this.onCloseModal()}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                <svg
                  className="h-6 w-6 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {noPaymentMethod
                    ? "You need to set up your payment first"
                    : zeroBalance
                    ? "Available balance $0.00"
                    : alreadyRequested
                    ? "You've already requested the transfer"
                    : "Request to transfer $" + currentUser.stats.balance}
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">
                    {noPaymentMethod
                      ? "Please go to the Payment Settings page to choose either Stripe or PayPal as an option to receive the money."
                      : zeroBalance
                      ? "You have no balance to transfer."
                      : alreadyRequested
                      ? "A transfer of $" +
                        currentUser.stats.balance +
                        " was requested on " +
                        format(
                          currentUser.pendingPayout.createdAt,
                          "MM/dd/yyyy"
                        ) +
                        ". Normally it takes 1 to 3 business days to process."
                      : "The $" +
                        currentUser.stats.balance +
                        " will be transferred to your PayPal account. The speed may vary and could take up to 3 business days to complete."}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                <button
                  type="button"
                  className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2  text-base leading-6 font-medium text-white shadow-sm  focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${
                    (zeroBalance || alreadyRequested) && !noPaymentMethod
                      ? "bg-gray-400 hover:bg-gray-300 focus:border-gray-400"
                      : "bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700"
                  }`}
                  disabled={
                    (zeroBalance || alreadyRequested) && !noPaymentMethod
                  }
                  onClick={() =>
                    noPaymentMethod ? this.onCloseModal() : this.onConfirm()
                  }
                >
                  {isConfirming ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#ffffff"} />
                  ) : noPaymentMethod ? (
                    "Got it"
                  ) : zeroBalance ? (
                    "Transfer $0.00"
                  ) : (
                    `${translate.confirm}`
                  )}
                </button>
              </span>
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => this.onCloseModal()}
                >
                  {translate.cancel}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestPayoutModal);
