import React from "react";
import PricingTable from "../../components/Common/PricingTable";
import CTA from "../../components/Common/CTA";

class Pricing extends React.Component {
  render() {
    return (
      <React.Fragment>
        <PricingTable />
        <CTA />
      </React.Fragment>
    );
  }
}

export default Pricing;
