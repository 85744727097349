import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

class PricingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      isYearly: true,
    };
  }
  componentDidMount() {
    var stripeObj = window.Stripe(
      "pk_live_51HdTLLIqdYxdRtVUdEKA9yApQWoYFd6ixu4nBHtiVRPDG0SM1NHoavkL3NacM1ns5hNVBnAKYT7vKYNCKfd40TlO00qoHESjMs"
    );
    this.setState({ stripe: stripeObj });
  }

  onGetStarted = (plan) => {
    const { currentUser } = this.props.user;
    const { stripe, isYearly } = this.state;

    if (currentUser) {
      if (plan === "podcaster") {
        stripe
          .redirectToCheckout({
            customerEmail: currentUser.email,
            lineItems: [
              // Replace with the ID of your price
              {
                price: isYearly
                  ? "price_1HdTXjIqdYxdRtVUnX7mdJC9" // yearly podcaster price id
                  : "price_1HdTZpIqdYxdRtVUdw9w4sFq", // monthly podcaster price id
                // price: isYearly //testing only
                //   ? "price_1HdUtYIqdYxdRtVUWyZXEI3T"
                //   : "price_1HdUtYIqdYxdRtVUurZPq9nC",
                quantity: 1,
              },
            ],
            mode: "subscription",
            successUrl: "https://influenswer.com/payment-success",
            cancelUrl: "https://influenswer.com/payment-later",
          })
          .then(async (result) => {
            await firebase
              .database()
              .ref("users")
              .child(currentUser.uid)
              .update({ stripeResult: result });
            console.log("result", result);
          });
      } else if (plan === "videoLite") {
        stripe
          .redirectToCheckout({
            customerEmail: currentUser.email,
            lineItems: [
              // Replace with the ID of your price
              {
                price: isYearly
                  ? "price_1HdTwHIqdYxdRtVUJQ9MNedO" // yearly videomaker lite price id
                  : "price_1HdTwHIqdYxdRtVUGoUHPrye", // monthly videomaker lite price id
                quantity: 1,
              },
            ],
            mode: "subscription",
            successUrl: "https://influenswer.com/payment-success",
            cancelUrl: "https://influenswer.com/payment-later",
          })
          .then(function (result) {});
      } else {
        // plan === "videoPro"
        stripe
          .redirectToCheckout({
            customerEmail: currentUser.email,
            lineItems: [
              // Replace with the ID of your price
              {
                price: isYearly
                  ? "price_1HdTxVIqdYxdRtVUa1nnCGNs" // yearly videomaker pro price id
                  : "price_1HdTxUIqdYxdRtVUMm3hOM4I", // monthly videomaker pro price id
                quantity: 1,
              },
            ],
            mode: "subscription",
            successUrl: "https://influenswer.com/payment-success",
            cancelUrl: "https://influenswer.com/payment-later",
          })
          .then(function (result) {});
      }
    } else {
      this.props.history.push("/signup");
    }
  };

  goToDashboard = () => {
    this.props.history.push("/messages");
  };

  render() {
    const { isYearly } = this.state;
    const { currentUser } = this.props.user;
    const stripeObj = currentUser && currentUser.stripe;
    const activePlan =
      stripeObj &&
      (stripeObj.subscriptionStatus === "trialing" ||
        stripeObj.subscriptionStatus === "active") &&
      stripeObj.invoiceStatus === "success";

    return (
      <div className="bg-white">
        <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
          <div className="sm:flex sm:flex-col sm:align-center">
            <p className="text-5xl leading-none font-extrabold text-gray-900 text-center">
              Simple Pricing
            </p>
            <p className="mt-3 max-w-4xl text-center mx-auto text-xl leading-7 text-gray-900 sm:mt-5 sm:text-2xl sm:leading-8">
              Pick your plan, you can always change later. 25% OFF for yearly
              plan. And{" "}
              <span className="text-indigo-600 font-bold">
                30-day money-back guarantee
              </span>
              .
            </p>
            <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
              <button
                type="button"
                className={`relative w-1/2 bg-gray-50 border-gray-200 rounded-l-md shadow-sm py-2 text-sm leading-5 font-semibold whitespace-no-wrap focus:outline-none focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 ${
                  isYearly
                    ? "text-gray-700 hover:text-gray-500"
                    : "bg-indigo-600 sm:text-white hover:text-white"
                }`}
                onClick={() => this.setState({ isYearly: false })}
              >
                Monthly
              </button>
              <button
                type="button"
                className={`relative w-1/2 bg-gray-50 text-white border border-transparent rounded-r-md py-2 text-sm leading-5 font-semibold   whitespace-no-wrap  focus:outline-none focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 ${
                  isYearly
                    ? "bg-indigo-600 sm:text-white hover:text-white"
                    : "text-gray-700 hover:text-gray-500"
                }`}
                onClick={() => this.setState({ isYearly: true })}
              >
                Yearly 🤩
              </button>
            </div>
          </div>
        </div>

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 h-5/6 bg-white lg:h-2/3"></div>
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-10 border-t-2 border-gray-100">
                        <div>
                          <h3
                            className="text-center text-2xl leading-8 font-medium text-gray-900"
                            id="tier-hobby"
                          >
                            Podcaster 🎙
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                              </span>
                              <span className="font-extrabold">
                                {isYearly ? 9 : 12}
                              </span>
                            </span>
                            <span className="text-xl leading-7 font-medium text-gray-500">
                              /month
                            </span>
                          </div>
                          {isYearly && (
                            <div className="mt-2 flex items-center justify-center">
                              <span className="text-base leading-8 font-medium text-gray-500">
                                Billed $108 per year
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Audio messages only
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Max audio recording duration 10 minutes
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Get paid by answering bounty questions
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Email notifications
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Free 14-day trial
                            </p>
                          </li>
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            {currentUser ? (
                              activePlan ? (
                                <button
                                  className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  aria-describedby="tier-hobby"
                                  onClick={this.goToDashboard}
                                >
                                  My dashboard
                                </button>
                              ) : (
                                <button
                                  className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  aria-describedby="tier-hobby"
                                  onClick={() => this.onGetStarted("podcaster")}
                                >
                                  Start your trial
                                </button>
                              )
                            ) : (
                              <button
                                className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                aria-describedby="tier-hobby"
                                onClick={() => this.onGetStarted("podcaster")}
                              >
                                Start your trial
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"></div>
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                          Most popular
                        </span>
                      </div>
                    </div>
                    <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                      <div>
                        <h3
                          className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6"
                          id="tier-growth"
                        >
                          Videomaker Lite 🎬
                        </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">
                              {isYearly ? 29 : 36}
                            </span>
                          </span>
                          <span className="text-2xl leading-8 font-medium text-gray-500">
                            /month
                          </span>
                        </div>
                        {isYearly && (
                          <div className="mt-2 flex items-center justify-center">
                            <span className="text-base leading-8 font-medium text-gray-500">
                              Billed $348 per year
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                      <ul>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Audio and{" "}
                            <span className="font-bold text-gray-800">
                              video messages
                            </span>
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Max audio recording duration 10 minutes
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Max video recording duration 2 minutes
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Get paid by answering bounty questions
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Email notifications
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg
                              className="h-6 w-6 text-green-500"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Free 14-day trial
                          </p>
                        </li>
                      </ul>
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          {currentUser ? (
                            activePlan ? (
                              <button
                                className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                                aria-describedby="tier-growth"
                                onClick={this.goToDashboard}
                              >
                                My dashboard
                              </button>
                            ) : (
                              <button
                                className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                                aria-describedby="tier-growth"
                                onClick={() => this.onGetStarted("videoLite")}
                              >
                                Start your trial
                              </button>
                            )
                          ) : (
                            <button
                              className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                              aria-describedby="tier-growth"
                              onClick={() => this.onGetStarted("videoLite")}
                            >
                              Start your trial
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-10 border-t-2 border-gray-100">
                        <div>
                          <h3
                            className="text-center text-2xl leading-8 font-medium text-gray-900"
                            id="tier-scale"
                          >
                            Videomaker Pro 🎥
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                              </span>
                              <span className="font-extrabold">
                                {isYearly ? 39 : 49}
                              </span>
                            </span>
                            <span className="text-xl leading-7 font-medium text-gray-500">
                              /month
                            </span>
                          </div>
                          {isYearly && (
                            <div className="mt-2 flex items-center justify-center">
                              <span className="text-base leading-8 font-medium text-gray-500">
                                Billed $468 per year
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul>
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base font-bold leading-6 font-medium text-gray-800">
                              Everything in Videomaker Lite and ...
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Max video recording duration 5 minutes
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Priority support
                            </p>
                          </li>
                          <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-6 w-6 text-green-500"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                              Free 14-day trial
                            </p>
                          </li>
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            {currentUser ? (
                              activePlan ? (
                                <button
                                  className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  aria-describedby="tier-scale"
                                  onClick={this.goToDashboard}
                                >
                                  My dashboard
                                </button>
                              ) : (
                                <button
                                  className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  aria-describedby="tier-scale"
                                  onClick={() => this.onGetStarted("videoPro")}
                                >
                                  Start your trial
                                </button>
                              )
                            ) : (
                              <button
                                className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                aria-describedby="tier-scale"
                                onClick={() => this.onGetStarted("videoPro")}
                              >
                                Start your trial
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PricingTable);
