import React, { useState, useEffect, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactStopwatch from "react-stopwatch";
import { v4 } from "uuid";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import PulseLoader from "react-spinners/PulseLoader";
import "./RecordAudioModal.css";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardForm from "../Stripe/StripeForm";

const MicRecorder = require("mic-recorder-to-mp3");

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function RecordAudioModal(props) {
  const [recorder, setRecorder] = useState(
    new MicRecorder({
      bitRate: 128,
    })
  );
  const [startRecordingButton, setStartRecordingButton] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [timeCounting, setTimeCounting] = useState(false);
  const [page, setPage] = useState(1);
  const [voiceBlob, setVoiceBlob] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [followerName, setFollowerName] = useState("");
  const [followerEmail, setFollowerEmail] = useState("");
  const [isBounty, setIsBounty] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const { bountyEnabled, creator } = props;

  const prevTimeCounting = usePrevious(timeCounting);

  useEffect(() => {
    if (prevTimeCounting === true && timeCounting === false) {
      changePage();
    }
  }, [timeCounting]);

  const { isReply } = props.user;

  const onCloseModals = () => {
    props.setUserValue({
      showSupportModal: false,
      showAudioModal: false,
      showVideoModal: false,
      creatorId: null,
      isDemo: false,
      videoLimit: null,
      isReply: false,
    });
    recorder.stop();
  };

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setTimeCounting(true);
      })
      .catch((e) => {
        console.error(e);
      });

    setIsRecording(true);
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setVoiceBlob(blob);
      })
      .catch((e) => {
        console.error(e);
      });

    setIsRecording(false);
    setTimeCounting(false);
  };

  const changePage = () => {
    setPage(2);
  };

  const onToggleRecordingBtn = () => {
    const oldState = startRecordingButton;
    setStartRecordingButton(!oldState);
    if (oldState) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const handleStripeSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const createPaymentIntent = firebase
      .functions()
      .httpsCallable("stripeCreatePaymentIntent");

    const paymentIntent = await createPaymentIntent({
      productAmount: creator.answerPrice * 100, // in cents
      stripeAccId: creator.stripe.accountId,
    });

    const clientSecret = paymentIntent.data.client_secret;

    const cardPayment = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    return cardPayment;
  };

  const onUploadAudio = async () => {
    const { creatorId, isDemo, isReply, replyTo } = props.user;

    setIsUploading(true);
    setStripeError(false);
    setNoEmail(false);

    if (isBounty && followerEmail === "") {
      setNoEmail(true);
      setIsUploading(false);
      return;
    }

    if (isDemo) {
      setIsUploading(false);
      setPage(page + 1);

      return;
    }

    if (isBounty && !isReply) {
      const stripePayment = await handleStripeSubmit();
      if (stripePayment.error) {
        setStripeError(true);
        setStripeErrorMsg(stripePayment.error.message);
        setIsUploading(false);
        return;
      } else {
        // successful payment
        var paymentIntent = stripePayment.paymentIntent;
        // save the payment intent id to the message for future refund reference
      }
    }

    const storageRef = await firebase
      .storage()
      .ref(isReply ? replyTo.creatorId : creatorId)
      .child("audio-messages")
      .child(v4());
    const uploadedFile = await storageRef.put(voiceBlob);
    const downloadURL = await uploadedFile.ref.getDownloadURL();

    const audioUUID = v4();

    try {
      if (isReply) {
        // the audio is a reply from creator
        await firebase
          .database()
          .ref("messages")
          .child(replyTo.creatorId)
          .child(replyTo.id)
          .update({
            reply: { downloadURL: downloadURL, createdAt: Date.now() },
          });

        const creatorRef = await firebase
          .database()
          .ref("users")
          .child(replyTo.creatorId)
          .once("value");

        await firebase
          .database()
          .ref("single-message")
          .child(replyTo.uuid)
          .update({
            repliedBy: {
              message: { downloadURL: downloadURL, createdAt: Date.now() },
              user: {
                name: creatorRef.val().name,
                email: creatorRef.val().email,
              },
            },
          });

        await firebase
          .database()
          .ref("bounty-messages")
          .child(replyTo.creatorId)
          .child(replyTo.id)
          .update({
            reply: { downloadURL: downloadURL, createdAt: Date.now() },
          });
      } else {
        // the audio is a message from follower
        const messageRef = await firebase
          .database()
          .ref("messages")
          .child(creatorId)
          .push({
            type: "Audio",
            downloadURL: downloadURL,
            sentBy: { name: followerName, email: followerEmail },
            isBounty: isBounty,
            price: creator.answerPrice,
            paymentIntentId: paymentIntent.id,
            creatorId: creatorId,
            createdAt: Date.now(),
            uuid: audioUUID,
          });

        const messageId = messageRef.key;
        await firebase
          .database()
          .ref("messages")
          .child(creatorId)
          .child(messageId)
          .update({ id: messageId });

        await firebase
          .database()
          .ref("single-message")
          .child(audioUUID)
          .update({
            type: "Audio",
            messageId: audioUUID,
            sentBy: {
              message: { downloadURL: downloadURL, createdAt: Date.now() },
              user: { name: followerName, email: followerEmail },
            },
          });

        if (isBounty) {
          // add to bounty-messages
          await firebase
            .database()
            .ref("bounty-messages")
            .child(creatorId)
            .child(messageId)
            .set({
              type: "Audio",
              downloadURL: downloadURL,
              sentBy: { name: followerName, email: followerEmail },
              isBounty: isBounty,
              price: creator.answerPrice,
              creatorId: creatorId,
              createdAt: Date.now(),
              id: messageId,
              uuid: audioUUID,
            });
        }
      }
    } catch (err) {
      console.log("3. database err: ", err);
    }

    setIsUploading(false);
    setPage(page + 1);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (event.target.id === "isBounty") {
      setIsBounty(event.target.checked);
    } else if (event.target.id === "followerName") {
      setFollowerName(value);
    } else {
      // followerEmail
      setFollowerEmail(value);
    }
  };

  switch (page) {
    case 1:
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => onCloseModals()}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-sm w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {isRecording && timeCounting ? (
                <>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-semibold text-gray-900"
                      id="modal-headline"
                    >
                      <i className="fa fa-circle fa-xs text-red-500 blinking"></i>
                      You're recording right now
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-500">
                        You can record for up to 10 minutes
                      </p>
                    </div>
                  </div>
                  <div className="my-auto">
                    <ReactStopwatch
                      seconds={0}
                      minutes={0}
                      hours={0}
                      autoStart={true}
                      limit="00:10:01"
                      onCallback={() => onToggleRecordingBtn()}
                      render={({ formatted }) => {
                        return (
                          <div className="text-center text-5xl mt-2">
                            <p>{formatted.slice(3)}</p>
                          </div>
                        );
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6 text-green-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                      />
                    </svg>
                  </div>

                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-semibold text-gray-900"
                      id="modal-headline"
                    >
                      {isReply
                        ? "Reply in a voice message 🎙"
                        : "Send me a voice message 🎙"}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-500">
                        {isReply
                          ? "Make a suprise for your follower!"
                          : "Your audio message will be sent to my inbox directly. Let me hear your voice!"}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-5 sm:mt-6">
                <span className="flex w-full rounded-md shadow-sm">
                  {isRecording && timeCounting ? (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => onToggleRecordingBtn()}
                    >
                      Stop recording
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => onToggleRecordingBtn()}
                    >
                      Start recording now!
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => onCloseModals()}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 text-green-600"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900"
                    id="modal-headline"
                  >
                    Review your message
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      The last step before sending your voice message
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-4 flex items-center justify-center">
                <ReactAudioPlayer
                  src={voiceBlob ? URL.createObjectURL(voiceBlob) : ""}
                  controls
                  autoPlay={false}
                  controlsList="nodownload"
                />
              </div>

              {!isReply && (
                <>
                  <div className="mt-4">
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="followerName"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Your name"
                          value={followerName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          id="followerEmail"
                          className="form-input block w-full sm:text-sm sm:leading-5"
                          placeholder="Your email"
                          value={followerEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                      {isBounty && (
                        <p
                          className={`mt-1 text-sm ${
                            noEmail ? "text-red-500" : "text-gray-500"
                          }`}
                        >
                          Email is required to receive a reply
                        </p>
                      )}
                    </div>
                  </div>
                  {bountyEnabled && (
                    <div className="mt-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="isBounty"
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            checked={isBounty}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div
                          className="ml-2 text-sm leading-5"
                          onClick={() => setIsBounty(!isBounty)}
                        >
                          <label
                            htmlFor="newMessageNotification"
                            className="font-medium text-indigo-700"
                          >
                            Pay ${creator.answerPrice} for a reply?
                          </label>
                          <p className="text-gray-500">
                            (you will be fully refunded if I can't answer you)
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {isBounty && (
                    <div className="mt-4">
                      <CardForm />
                      {stripeError && (
                        <p className="mt-1 text-sm text-red-500">
                          {stripeErrorMsg}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => onUploadAudio()}
                  >
                    {isUploading || voiceBlob === null ? (
                      <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                    ) : (
                      "Confirm and send"
                    )}
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-semibold text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setPage(1)}
                  >
                    Record again
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => onCloseModals()}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="text-center">
                  <div>
                    <img
                      className="mx-auto rounded"
                      src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
                      alt="success"
                    />
                  </div>
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900 mt-4"
                    id="modal-headline"
                  >
                    Thanks for your message 👏
                  </h3>
                </div>
              </div>
              <div className="mt-4">
                <span className="flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => onCloseModals()}
                  >
                    Close
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RecordAudioModal);
