import React from "react";
import Typed from "react-typed";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Localizedtranslate from "react-localization";
import PlayVideoModal from "../../components/Modals/PlayVideoModal";
import { setUserValue } from "../../redux/action/userAction";
import "react-typed/dist/animatedCursor.css";

let translate = new Localizedtranslate({
  en: {
    //English
    getPaid: "Get paid",
    toAnswer: "to answer",
    yourFollowers: "your followers",
    yourSubscribers: "your subscribers",
    yourListeners: "your listeners",
    yourAudience: "your audience",
    yourFans: "your fans",
    heroSubtitle:
      "Let your true followers ask you questions. Simply answer their questions and get paid.",
    withoutAds: "You could make a living without sponsorships and ads!",
    myDashboard: "My dashboard",
    requestToJoin: "Request to join",
    liveDemo: "Live demo",
  },
  sp: {
    //Spanish
    getPaid: "Gana dinero",
    toAnswer: "respondiendo a",
    yourFollowers: "tus seguidores",
    yourSubscribers: "tus suscriptores",
    yourListeners: "tus oyentes",
    yourAudience: "tus audiencia",
    yourFans: "tus fans",
    heroSubtitle:
      "Deja que tus verdaderos seguidores te hagan preguntas. Simplemente responda sus preguntas y reciba el pago.",
    withoutAds: "¡Podrías ganarte la vida sin patrocinios ni anuncios!",
    myDashboard: "Mi tablero",
    requestToJoin: "Solicita para ingresar",
    liveDemo: "Demo en vivo",
  },
});

class HeroSection extends React.Component {
  render() {
    translate.setLanguage("en");

    const { showPlayVideoModal, currentUser } = this.props.user;
    const stripeObj = currentUser && currentUser.stripe;
    const activePlan =
      stripeObj &&
      (stripeObj.subscriptionStatus === "trialing" ||
        stripeObj.subscriptionStatus === "active") &&
      stripeObj.invoiceStatus === "success";

    return (
      <div className="relative bg-white overflow-hidden">
        {showPlayVideoModal && <PlayVideoModal />}
        <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
          <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-xl md:mx-auto lg:col-span-6 lg:text-left">
                {/* <h3 className="mt-1 text-4xl tracking-tight leading-10 font-bold text-gray-800 sm:leading-none">
                  Get paid by
                </h3> */}
                <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-5xl lg:text-5xl xl:text-6xl">
                  <span className="text-indigo-600">{translate.getPaid}</span>{" "}
                  {translate.toAnswer}
                  <span className="typed-js-list">
                    <Typed
                      strings={[
                        translate.yourFollowers,
                        translate.yourSubscribers,
                        translate.yourListeners,
                        translate.yourAudience,
                        translate.yourFans,
                      ]}
                      typeSpeed={40}
                      backSpeed={50}
                      attr="placeholder"
                      loop
                    >
                      <input
                        type="text"
                        disabled
                        style={{ background: "transparent", outline: "none" }}
                      />
                    </Typed>
                  </span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {translate.heroSubtitle}{" "}
                  <span className="text-gray-600 font-bold">
                    {translate.withoutAds}
                  </span>
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    {currentUser ? (
                      <a
                        href="/messages"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        {translate.myDashboard}
                      </a>
                    ) : (
                      <a
                        href="/request"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        {translate.requestToJoin}
                      </a>
                    )}
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href="/creator-page-demo"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      {translate.liveDemo}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-full rounded-lg shadow-xl lg:max-w-xl lg:ml-5">
                  <div className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline border-solid border-2 border-gray-500">
                    <img
                      className="w-full"
                      src="/assets/hero-demo-video.png"
                      alt="Creator page"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HeroSection);
