import React from "react";
import Linkify from "linkifyjs/react";
import ReactMarkdown from "react-markdown";
import Localizedtranslate from "react-localization";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import SupportModal from "../../components/Modals/SupportModal";
import RecordAudioModal from "../../components/Modals/RecordAudioModal";
import RecordVideoModal from "../../components/Modals/RecordVideoModal";

import LinkRenderer from "../../components/Utils/LinkRenderer";

let translate = new Localizedtranslate({
  en: {
    //English
    recordVideoText: "Record a video message",
    demoDashboardTest: "Demo dashboard page",
    askMeAQuestion: "Ask me a question?",
    price: "Price",
    repliesIn: "Replies in",
    threeDays: "3 days",
  },
  sp: {
    //Spanish
    recordVideoText: "Grabar un video mensaje.",
    demoDashboardTest: "Página del tablero",
    askMeAQuestion: "¿Hazme una pregunta?",
    price: "Precio",
    repliesIn: "Responde en 1 dia",
    threeDays: "3 dias",
  },
});

class CreatorDetails extends React.Component {
  render() {
    translate.setLanguage("en");

    const { user, creator, isDemo } = this.props;
    const { showSupportModal, showAudioModal, showVideoModal } = user;
    const bountyEnabled =
      creator.stripe &&
      creator.stripe.details_submitted &&
      creator.answerPrice !== undefined &&
      creator.answerPrice
        ? true
        : false;

    var videoLimit = null;
    var audioOnly = false;

    videoLimit = 120000;

    return (
      <div className="col-span-12 md:col-span-7 text-content px-8 sm:px-4 md:pr-0 md:pb-4 lg:pb-8">
        {showSupportModal && <SupportModal />}
        {showAudioModal && (
          <RecordAudioModal bountyEnabled={bountyEnabled} creator={creator} />
        )}
        {showVideoModal && <RecordVideoModal creator={creator} />}

        <div className="titles md:hidden">
          <h1 className="mb-2">{creator.name}</h1>

          <div className="subtitle text-gray-600">{creator.shortIntro}</div>

          <div className="btn-wrapper mt-2 md:hidden">
            {/* <button
              className="btn btn-primary btn-audio mr-4 mb-4 p-3"
              onClick={() =>
                this.props.setUserValue({
                  showAudioModal: true,
                  creatorId: creator.uid,
                  isDemo: isDemo,
                })
              }
            >
              <i className="fas fa-microphone fa-lg mr-2"></i>Record a voice
              message
            </button> */}
            {((!audioOnly && !isDemo) || isDemo) && (
              <button
                className="btn btn-video mb-4 py-2 px-3"
                onClick={() =>
                  this.props.setUserValue({
                    showVideoModal: true,
                    creatorId: creator.uid,
                    isDemo: isDemo,
                    videoLimit: videoLimit,
                  })
                }
              >
                <i className="fas fa-video fa-lg mr-2"></i>
                {translate.recordVideoText}
              </button>
            )}
            {isDemo && (
              <button
                className="btn btn-support mb-4 py-2 px-3"
                onClick={() => this.props.history.push("/dashboard-page-demo")}
              >
                {translate.demoDashboardTest}
                <i className="fas fa-arrow-right fa-lg ml-2"></i>
              </button>
            )}
          </div>
        </div>

        <div className="about-text text-gray-500">
          <Linkify style={{ whiteSpace: "pre-line" }}>
            <ReactMarkdown
              source={creator.about}
              renderers={{ link: LinkRenderer }}
            />
          </Linkify>
        </div>
        <div className="services-box">
          <p className="text-muted text-sm text-gray-900 font-semibold">
            {translate.askMeAQuestion}
          </p>
          <ul className="grid grid-cols-2 gap-1 sm:grid-cols-3">
            <li className="col-span-1 bg-white rounded-lg ">
              <div className="w-full flex items-center justify-between py-2 pr-6 space-x-6">
                <div className="flex-1">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-600 text-lg leading-5 font-semibold truncate">
                      {translate.price}
                    </h3>
                  </div>
                  <p className="mt-3 text-gray-600 text-xl leading-5 font-bold">
                    {creator.answerPrice === "free" ||
                    !creator.answerPrice ||
                    creator.answerPrice === "undefined"
                      ? "Free"
                      : creator.answerPrice === "undefined"
                      ? "Not provided"
                      : "$" + creator.answerPrice}
                  </p>
                </div>
              </div>
            </li>

            <li className="col-span-1 bg-white rounded-lg">
              <div className="w-full flex items-center justify-between py-2 pr-6 space-x-6">
                <div className="flex-1">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-600 text-lg leading-5 font-semibold truncate">
                      {translate.repliesIn}
                    </h3>
                  </div>
                  <p className="mt-3 text-gray-600 text-xl leading-5 font-bold">
                    ⚡️ &#x3c; {translate.threeDays}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreatorDetails);
