import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import configureStore from "./store";
import * as serviceWorker from "./serviceWorker";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const store = configureStore();

Sentry.init({
  dsn:
    "https://75911e418a0c4b1bb4c3cd1b723e99d4@o382460.ingest.sentry.io/5527097",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,
});

const firebaseConfig = {
  apiKey: "AIzaSyA-DOdqq8WLbTmsfrX_3FvqRiqN-qlL0Jg",
  authDomain: "influenswer.firebaseapp.com",
  databaseURL: "https://influenswer.firebaseio.com",
  projectId: "influenswer",
  storageBucket: "influenswer.appspot.com",
  messagingSenderId: "913211963023",
  appId: "1:913211963023:web:5c246530f65760ecd5e2fa",
  measurementId: "G-GPT15JP543",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
