import React from "react";
import "./PaymentStatus.css";

class FailurePage extends React.Component {
  render() {
    return (
      <div className="bg-white payment-success-container">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-none lg:tracking-normal lg:text-6xl">
              Oops! Something bad happened
            </p>

            <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              Don't worry! Your card might get deciline. Please reach out to our
              support team to get it fixed.
            </p>

            <img
              className="my-5 mx-auto rounded-lg"
              src="https://media.giphy.com/media/iqzp1pBJtNQUU/giphy.gif"
              alt="failure"
            />

            <a
              href="mailto: damon@influenswer.com"
              className="underline cursor-pointer font-medium text-gray-500 hover:text-gray-400 focus:underline transition ease-in-out duration-150"
            >
              Send an email ✉️
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FailurePage;
