import React, { useRef } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import PulseLoader from "react-spinners/PulseLoader";
import "./SingleMessage.css";

import AudioMessage from "./AudioMessage";
import VideoMessage from "./VideoMessage";

import MessageAccessModal from "../../components/Modals/MessageAccessModal";

var unsubscribe = null;

class SingleMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      verified: false,
      verifyFailed: false,
      showMessageAccessModal: false,
    };
  }

  componentDidMount() {
    const messageId = this.props.match.params.messageId;

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      const messageRef = await firebase
        .database()
        .ref("single-message")
        .child(messageId)
        .once("value");

      if (!messageRef.val()) {
        this.props.history.push("/error");
      }

      if (user) {
        const repliedBy = messageRef.val().repliedBy;
        if (repliedBy.user.email !== user.email) {
          this.setState({ showMessageAccessModal: true });
        } else {
          this.setState({ verified: true });
        }
      } else {
        this.setState({ showMessageAccessModal: true });
      }

      this.setState({ message: messageRef.val() });
    });
  }

  componentWillUnmount = () => {
    unsubscribe && unsubscribe();
  };

  closeModal = () => {
    this.setState({ showMessageAccessModal: false });
  };

  verifyPermission = (email) => {
    const { message } = this.state;
    const repliedBy = message.repliedBy;
    const sentBy = message.sentBy;

    if (email === sentBy.user.email || email === repliedBy.user.email) {
      this.setState({ verified: true });
      this.closeModal();
    } else {
      this.setState({ verifyFailed: true });
    }
  };

  render() {
    const {
      message,
      verified,
      verifyFailed,
      showMessageAccessModal,
    } = this.state;

    if (!message) {
      return (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen video-page-container">
          <div className="mt-2 text-center">
            <PulseLoader sizeUnit={"px"} size={15} color={"#5850ec"} />
          </div>
        </div>
      );
    }

    if (!verified) {
      return (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 min-h-screen video-page-container">
          {showMessageAccessModal && (
            <MessageAccessModal
              verifyFailed={verifyFailed}
              verifyPermission={this.verifyPermission}
            />
          )}
        </div>
      );
    }

    return message.type === "Audio" ? (
      <AudioMessage message={message} />
    ) : (
      <VideoMessage message={message} />
    );
  }
}

export default SingleMessage;
