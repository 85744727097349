import React from "react";

class CreatorVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlayed: false,
      options: {
        autoPlay: true,
        muted: true,
      },
    };
  }

  handleClick = () => {
    var introVid = document.getElementById("intro-vid");

    if (introVid.paused) introVid.play();
    else introVid.pause();

    this.setState({
      isPlayed: !this.state.isPlayed,
      options: {
        autoplay: !this.state.options.autoplay,
        muted: !this.state.options.muted,
      },
    });
  };

  render() {
    const { isPlayed, options } = this.state;
    const { videoSrc, videoPoster, isDemo } = this.props;

    return (
      <div className="col-span-12 md:col-span-5 md:pb-4 lg:pb-8">
        <div
          className={`ml-0 video-wrapper ${
            isPlayed ? "full-screen" : ""
          } ${"visible"}`}
          onClick={this.handleClick}
        >
          <video
            id="intro-vid"
            width="100%"
            height="100%"
            min-height="640"
            min-width="360"
            playsInline
            loop
            poster={videoPoster}
          >
            <source src={videoSrc || ""} type="video/mp4" />
          </video>
          <div className={`overlay ${isPlayed ? "active" : ""}`}>
            {isDemo && (
              <img
                src="/assets/muted.svg"
                className={`watermark ${isPlayed ? "active" : ""}`}
                alt="logo"
              />
            )}
            <span className="svg-icon">
              {isPlayed ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="6" y="4" width="4" height="16"></rect>
                  <rect x="14" y="4" width="4" height="16"></rect>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatorVideo;
