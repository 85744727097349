import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "You need to have permission first",
    subtitle:
      "The message can only be viewed by people who asked and answered! Provide your email and we will verify it.",
    verify: "Verify",
    error: "Sorry, we couldn't verify your email",
  },
  sp: {
    //Spanish
    title: "Primero debes tener permiso",
    subtitle:
      "¡El mensaje solo puede ser visto por personas que preguntaron y respondieron! Proporcione su correo electrónico y lo verificaremos.",
    verify: "Verificar",
    error: "Lo sentimos, no pudimos verificar su correo electrónico",
  },
});

class MessageAccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    translate.setLanguage("en");

    const { email } = this.state;
    const { verifyFailed, verifyPermission } = this.props;

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="text-center">
                <h3
                  className="text-lg leading-6 font-semibold text-gray-900 mt-4"
                  id="modal-headline"
                >
                  {translate.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">
                    {translate.subtitle}
                  </p>
                </div>
                <div className="mt-5">
                  <input
                    aria-label="Email address"
                    name="email"
                    required
                    className="appearance-none w-full p-2 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Enter your email to verify"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => verifyPermission(email)}
                >
                  {translate.verify}
                </button>
              </span>
              {verifyFailed && (
                <p className="mt-1 text-sm text-red-500">{translate.error}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(MessageAccessModal);
