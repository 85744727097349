import React from "react";
import HeroSection from "./HeroSection";
import FeatureSet from "./FeatureSet";
import FeaturedVideos from "./FeaturedVideos";
import FAQ from "../../components/Common/FAQ";
import PricingTable from "../../components/Common/PricingTable";
import CTA from "../../components/Common/CTA";
import "./HomePage.css";

class HomePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSection />
        <FeatureSet />
        {/* <FeaturedVideos /> */}
        {/* <PricingTable /> */}
        <FAQ />
        <CTA />
      </React.Fragment>
    );
  }
}

export default HomePage;
