import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    ctaTitle: "Ready to be an",

    ctaSubtitle1: "You get paid simply by answering questions",
    ctaSubtitle2: "No monthly fee. We make money only when you do.",
    ctaSubtitle3:
      "Your knowledge is money. Let's make a living without sponsorships & ads!",
    requestToJoin: "Request to join",
    liveDemo: "Live demo",
    myDashboard: "My dashboard",
  },
  sp: {
    //Spanish
    ctaTitle: "¿Estas listo para ser un",
    ctaSubtitle1: "Gana dinero simplemente respondiendo preguntas.  ",
    ctaSubtitle2: "Sin cuota mensual. Ganamos dinero solo cuando tú lo haces.",
    ctaSubtitle3:
      "Tu conocimiento es dinero. ¡Vamos a ganarnos la vida sin patrocinios ni anuncios!",
    requestToJoin: "Solicita para ingresar",
    liveDemo: "Demo en vivo",
    myDashboard: "Mi tablero",
  },
});

class CTA extends React.Component {
  render() {
    translate.setLanguage("en");

    const { currentUser } = this.props.user;
    const stripeObj = currentUser && currentUser.stripe;
    const activePlan =
      stripeObj &&
      (stripeObj.subscriptionStatus === "trialing" ||
        stripeObj.subscriptionStatus === "active") &&
      stripeObj.invoiceStatus === "success";

    return (
      <div className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {translate.ctaTitle}{" "}
            <span className="text-indigo-600 typed-js-list">Influenswer</span>?
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            {translate.ctaSubtitle1}{" "}
            <span className="text-gray-600 font-bold">
              {translate.ctaSubtitle2}
            </span>{" "}
            {translate.ctaSubtitle3}
          </p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              {currentUser ? (
                <a
                  href="/messages"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  {translate.myDashboard}
                </a>
              ) : (
                <a
                  href="/request"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  {translate.requestToJoin}
                </a>
              )}
            </div>
            <div className="ml-3 inline-flex">
              <a
                href="/creator-page-demo"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
              >
                {translate.liveDemo}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(CTA);
