import { combineReducers } from "redux";
import userReducer from "./userReducer";
import asyncReducer from "./asyncReducer";

const reducer = combineReducers({
  user: userReducer,
  async: asyncReducer,
});

export default reducer;
