import React from "react";

class FeaturedVideos extends React.Component {
  render() {
    return (
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              They are real influencers
            </h3>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            <div className="flex space-x-3">
              <video
                id="intro-vid"
                width="100%"
                height="100%"
                min-height="640"
                min-width="360"
                playsInline
                loop
                poster={
                  "https://image.mux.com/EqeGyEsoVFlbXOLNJ5FTLXU2H7tuwwwriHWs0001RRnOU/thumbnail.jpg?time=0"
                }
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/fajarsiddiq%2Fprofile%2Fvid-poster.png?alt=media&token=92133f40-8a3e-43ce-82fa-fdf718b04f8a"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="flex space-x-3">
              <video
                id="intro-vid"
                width="100%"
                height="100%"
                min-height="640"
                min-width="360"
                playsInline
                loop
                poster={
                  "https://image.mux.com/EqeGyEsoVFlbXOLNJ5FTLXU2H7tuwwwriHWs0001RRnOU/thumbnail.jpg?time=0"
                }
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/fajarsiddiq%2Fprofile%2Fvid-poster.png?alt=media&token=92133f40-8a3e-43ce-82fa-fdf718b04f8a"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="flex space-x-3">
              <video
                id="intro-vid"
                width="100%"
                height="100%"
                min-height="640"
                min-width="360"
                playsInline
                loop
                poster={
                  "https://image.mux.com/EqeGyEsoVFlbXOLNJ5FTLXU2H7tuwwwriHWs0001RRnOU/thumbnail.jpg?time=0"
                }
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/fajarsiddiq%2Fprofile%2Fvid-poster.png?alt=media&token=92133f40-8a3e-43ce-82fa-fdf718b04f8a"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="flex space-x-3">
              <video
                width="100%"
                height="100%"
                min-height="640"
                min-width="360"
                className="rounded-lg"
                playsInline
                loop
                poster={
                  "https://image.mux.com/EqeGyEsoVFlbXOLNJ5FTLXU2H7tuwwwriHWs0001RRnOU/thumbnail.jpg?time=0"
                }
              >
                <source
                  className="rounded-lg"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/fajarsiddiq%2Fprofile%2Fvid-poster.png?alt=media&token=92133f40-8a3e-43ce-82fa-fdf718b04f8a"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </dl>
        </div>
      </div>
    );
  }
}

export default FeaturedVideos;
