import React from "react";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    title: "Don't know the anwer?",
    subtitle:
      "No one knows answers to all questions. If you don't know how to answer, it is totally OK. Your follower will get full refund and be notified.",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  sp: {
    //Spanish
    title: "¿No conoces la respuesta?",
    subtitle:
      "Nadie sabe las respuestas a todas las preguntas. Si no sabe cómo responder, está bien. Su seguidor recibirá un reembolso completo y será notificado.",
    cancel: "Cancelar",
    confirm: "Confirmar",
  },
});

class NoAnswerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirming: false,
    };
  }

  onCloseModal = () => {
    this.props.setUserValue({
      showNoAnswerModal: false,
    });
  };

  onConfirm = async () => {
    const { message, currentUser } = this.props.user;

    this.setState({ isConfirming: true });

    if (!currentUser.paymentMethod || currentUser.paymentMethod === "stripe") {
      const stripeIssueRefund = firebase
        .functions()
        .httpsCallable("stripeIssueRefund");

      var stripeRefund = await stripeIssueRefund({
        intentId: message.paymentIntentId, //get the charge id from each message
        stripeAccId: currentUser.stripe.accountId,
        amount: message.price, //save price to each message
        isFullRefund: true,
      });

      await firebase
        .database()
        .ref("messages")
        .child(message.creatorId)
        .child(message.id)
        .update({
          refund: {
            from: "stripe",
            amount: message.price * 0.95,
            status: stripeRefund.data.status, // "succeeded" if success
            createdAt: Date.now(),
          },
        });

      await firebase.database().ref("refund-requests").push({
        via: "Stripe",
        intentId: message.paymentIntentId, //get the charge id from each message
        stripeAccId: currentUser.stripe.accountId,
        amount: message.price, //save price to each message
        isFullRefund: true,
        status: stripeRefund.data.status,
      });
    } else {
      const paypalIssueRefund = firebase
        .functions()
        .httpsCallable("paypalIssueRefund");

      var paypalRefund = await paypalIssueRefund({
        orderId: message.paypal.orderId, //get the paypal capture id from each message
        amount: message.price, //save price to each message
      });

      await firebase
        .database()
        .ref("messages")
        .child(message.creatorId)
        .child(message.id)
        .update({
          refund: {
            from: "paypal",
            amount: message.price * 0.95,
            status: paypalRefund.data.result.status, // "COMPLETED" if success
            createdAt: Date.now(),
          },
        });

      await firebase.database().ref("refund-requests").push({
        via: "PayPal",
        orderId: message.paypal.orderId,
        amount: message.price, //save price to each message
        isFullRefund: true,
        status: "pending",
      });
    }

    this.setState({ isConfirming: false });

    // TODO: handle refund failure

    this.onCloseModal();
  };

  render() {
    translate.setLanguage("en");

    const { isConfirming } = this.state;

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                <svg
                  className="h-6 w-6 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {translate.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">
                    {translate.subtitle}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => this.onConfirm()}
                >
                  {isConfirming ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#ffffff"} />
                  ) : (
                    `${translate.confirm}`
                  )}
                </button>
              </span>
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => this.onCloseModal()}
                >
                  {translate.cancel}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NoAnswerModal);
