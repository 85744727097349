import React from "react";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import { formatDistance } from "date-fns";
import { eo } from "date-fns/locale";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import VideoPlayer from "../../components/Utils/VideoPlayer/VideoPlayer";
import Axios from "axios";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    name: "NAME",
    email: "EMAIL",
    reply: "Reply",
    replied: "Replied",
    archive: "Archive",
    unArchive: "Unarchive",
    notGiven: "Not Given",
    download: "Download",
    noAnswer: "No answer",
    video: "Video",
    audio: "Audio",
    ago: "ago",
  },
  sp: {
    //Spanish
    name: "NOMBRE",
    email: "CORREO ELECTRÓNICO",
    reply: "Respuesta",
    replied: "Respondida",
    archive: "Archivo",
    unArchive: "Desarchivar",
    notGiven: "No dado",
    download: "Descargar",
    noAnswer: "Sin respuesta",
    video: "Vídeo",
    audio: "Audio",
    ago: "hace",
  },
});

var fileDownload = require("js-file-download");

class MessageItem extends React.Component {
  onReplyInAudio = () => {
    const { data: message, isDemo } = this.props;

    this.props.setUserValue({
      showAudioModal: true,
      isReply: true,
      isDemo: isDemo,
      replyTo: message,
    });
  };

  onReplyInVideo = () => {
    const { data: message, isDemo } = this.props;

    this.props.setUserValue({
      showVideoModal: true,
      isDemo: isDemo,
      isReply: true,
      replyTo: message,
    });
  };

  onNoAnswer = async () => {
    const { data: message, isDemo } = this.props;

    if (isDemo) {
      toast("No answer is disabled on demo page.", {
        autoClose: 4000,
      });
      return;
    }

    if (!message.isBounty) {
      return; // impossible!
    }

    this.props.setUserValue({ showNoAnswerModal: true, message: message });
  };

  onArchive = async (message) => {
    const { user, setIsOpen, isDemo, archiveMsg } = this.props;

    setIsOpen(false);

    if (isDemo) {
      archiveMsg(message);
      return;
    }

    // add to "archived"
    const messageRef = await firebase
      .database()
      .ref("messages")
      .child(user.uid)
      .child(message.id)
      .once("value");

    await firebase
      .database()
      .ref("archived")
      .child(user.uid)
      .child(message.id)
      .set(messageRef.val());
  };

  onUnarchive = async (message) => {
    const { user, setIsOpen, isDemo, unarchiveMsg } = this.props;

    setIsOpen(false);

    if (isDemo) {
      unarchiveMsg(message);
      return;
    }

    // remove from "archived"
    await firebase
      .database()
      .ref("archived")
      .child(user.uid)
      .child(message.id)
      .remove();
  };

  render() {
    translate.setLanguage("en");

    const {
      id,
      isOpen,
      setIsOpen,
      data: message,
      isArchived,
      isDemo,
      user,
    } = this.props;

    var audioURL = "";
    var videoPoster = "";
    var videoStreamingURL = "";
    const downloadURL = message.downloadURL;
    const fileName =
      "influenswer-" +
      v4().split("-")[0] +
      (message.type === "Video" ? ".mp4" : ".mp3");

    if (message.type === "Video") {
      videoPoster =
        "https://image.mux.com/" +
        message.playbackIds[0].id +
        "/thumbnail.png?width=400";
      videoStreamingURL =
        "https://stream.mux.com/" + message.playbackIds[0].id + ".m3u8";
    } else {
      audioURL = message.downloadURL;
    }

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: true,
      controls: true,
      fluid: true,
      // aspectRatio: "16:9",
    };

    return (
      <div
        className={
          isOpen === id
            ? "collapsible mb-4 border border-gray-300 rounded-lg"
            : "collapsible mb-4 hover:bg-gray-100 transition ease-in-out duration-150 rounded-lg"
        }
      >
        <div
          className="block focus:outline-none transition duration-150 ease-in-out hover:cursor-pointer w-full"
          onClick={() => setIsOpen(isOpen === id ? false : id)}
        >
          <div
            className={
              isOpen === id ? "px-4 pt-4 sm:px-6" : "px-4 py-4 sm:px-6"
            }
          >
            <div className="items-center">
              <div className="flex w-full">
                {message.reply ? ( //replied messages
                  <span className="px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-500 text-left">
                    Replied
                  </span>
                ) : message.refund === undefined ? (
                  <span className="px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-orange-100 text-orange-500 text-left">
                    Pending
                  </span>
                ) : (
                  <span className="px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-100 text-red-500 text-left">
                    No answer
                  </span>
                )}

                <span className="mt-2 ml-auto leading-5 text-gray-400 font-semibold sm:mt-0 text-sm">
                  {formatDistance(message.createdAt, Date.now())} ago
                </span>
              </div>

              <div className="text-base font-medium text-gray-900 truncate text-left my-2">
                {isOpen === id ? null : (
                  <div>
                    {message.type === "Audio" ? (
                      <button className="text-gray-600 font-semibold hover:text-gray-500 focus:outline-none w-full flex items-center justify-left">
                        <img
                          src={"/assets/play-audio.png"}
                          alt=""
                          className="message_thumbnail "
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="relative block rounded-lg overflow-hidden focus:outline-none focus:shadow-outline"
                      >
                        <img
                          src={videoPoster}
                          alt=""
                          className="message_thumbnail "
                        />

                        <div className="absolute inset-0 w-full h-full flex items-center justify-center ">
                          <svg
                            className="h-10 w-10 text-indigo-500"
                            fill="currentColor"
                            viewBox="0 0 84 84"
                          >
                            <circle
                              opacity="0.9"
                              cx="42"
                              cy="42"
                              r="42"
                              fill="white"
                            />
                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                          </svg>
                        </div>
                      </button>
                    )}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      className="ml-auto mt-2 svg-inline--fa fa-chevron-down fa-w-14 w-2"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#a0aec0"
                        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                      ></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isOpen === id && (
          <div className="collapsible text-right">
            <button
              className="text-left focus:outline-none w-full"
              // onClick={() => setIsOpen(isOpen === id ? false : id)}
            >
              <div className="mx-6 mb-4 text-base font-medium">
                {message.type === "Audio" ? (
                  <ReactAudioPlayer
                    src={audioURL}
                    controls
                    autoPlay
                    controlsList="nodownload"
                  />
                ) : (
                  <VideoPlayer
                    {...videoJsOptions}
                    poster={videoPoster}
                    src={videoStreamingURL}
                  />
                )}
              </div>
            </button>
            {message.additionalMessage && message.additionalMessage !== "" && (
              <div className="mx-6 mb-4 text-sm font-normal text-left">
                <p className="text-gray-400 font-semibold">
                  ADDITIONAL MESSAGE
                </p>
                <p>{message.additionalMessage}</p>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              <div className="mx-6 mb-4 text-sm font-semibold text-left">
                <p className="text-gray-400">{translate.name}</p>
                <p>
                  {message.sentBy.name === ""
                    ? translate.notGiven
                    : message.sentBy.name}
                </p>
              </div>

              <div className="mx-6 mb-4 text-sm font-semibold text-left">
                <p className="text-gray-500">{translate.email}</p>
                <p>
                  {message.sentBy.email === ""
                    ? translate.notGiven
                    : message.sentBy.email}
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse">
              {message.isBounty && !message.reply && !message.refund && (
                <button
                  className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150"
                  onClick={() => this.onNoAnswer()}
                >
                  <i className="far fa-frown fa-sm mr-2"></i>
                  {translate.noAnswer}
                </button>
              )}
              {!isDemo && (
                <button
                  className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150 cursor-pointer"
                  onClick={() => {
                    if (isDemo) {
                      toast("Download is disabled on demo page.", {
                        autoClose: 4000,
                      });
                      return;
                    }
                    Axios({
                      url: downloadURL,
                      method: "GET",
                      responseType: "blob", // important
                    }).then((res) => {
                      fileDownload(res.data, fileName);
                    });
                  }}
                >
                  <i className="fas fa-download fa-sm mr-2"></i>
                  {translate.download}
                </button>
              )}
              {isArchived ? (
                <button
                  className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150"
                  onClick={() => this.onUnarchive(message)}
                >
                  <i className="far fa-star fa-sm mr-2"></i>
                  {translate.unArchive}
                </button>
              ) : (
                <button
                  className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150"
                  onClick={() => this.onArchive(message)}
                >
                  <i className="fas fa-star fa-sm mr-2"></i>
                  {translate.archive}
                </button>
              )}

              {((message.sentBy.email !== "" && message.type === "Audio") ||
                (isDemo && message.type === "Audio")) &&
                !message.refund &&
                (message.reply ? (
                  <button className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-green-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150">
                    {translate.replied} 👍
                  </button>
                ) : (
                  <button
                    className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150"
                    onClick={() => this.onReplyInAudio()}
                  >
                    <i className="fas fa-microphone fa-sm mr-2"></i>
                    {translate.reply}
                  </button>
                ))}

              {((user &&
                user.stripe &&
                message.sentBy.email !== "" &&
                message.type === "Video") ||
                (isDemo && message.type === "Video")) &&
                !message.refund &&
                (message.reply ? (
                  <button className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-green-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150">
                    {translate.replied} 👍
                  </button>
                ) : (
                  <button
                    className="mx-2 mb-4 focus:outline-none text-sm font-bold text-gray-700 bg-gray-100 rounded px-2 py-1 hover:text-gray-500 transition ease-in-out duration-150"
                    onClick={() => this.onReplyInVideo()}
                  >
                    <i className="fas fa-video fa-sm mr-2"></i>
                    {translate.reply}
                  </button>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(MessageItem);
