import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

class StripeSupportedCountriesModal extends React.Component {
  onCloseModals = () => {
    this.props.setUserValue({
      showStripeSupportedCountriesModal: false,
    });
  };

  render() {
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => this.onCloseModals()}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="text-center">
                <h3
                  className="text-lg leading-6 font-semibold text-gray-900 mt-4"
                  id="modal-headline"
                >
                  Stripe only supports these 37 countries/locations
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">
                    Please make sure your beloved country is in the list. If
                    not, don't worry, you can choose to use PayPal instead.
                  </p>
                </div>
                <div className="mt-2">
                  <img
                    className="mx-auto rounded"
                    src="/assets/stripe-countries-list.png"
                    alt="stripe-countries-list"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => this.onCloseModals()}
                >
                  Close
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(StripeSupportedCountriesModal);
