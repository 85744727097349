import React from "react";
import Localizedtranslate from "react-localization";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";

import RecordVideoModal from "../../components/Modals/RecordVideoModal";

let translate = new Localizedtranslate({
  en: {
    //English
    recordVideoText: "Record a video message",
    demoDashboardTest: "Demo dashboard page",
    askMeAQuestion: "Ask me a question?",
    price: "Price",
    repliesIn: "Replies in",
    threeDays: "3 days",
  },
  sp: {
    //Spanish
    recordVideoText: "Grabar un video mensaje.",
    demoDashboardTest: "Página del tablero",
    askMeAQuestion: "¿Hazme una pregunta?",
    price: "Precio",
    repliesIn: "Responde en 1 dia",
    threeDays: "3 dias",
  },
});

class CreatorHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlayed: false,
      options: {
        autoPlay: true,
        muted: true,
      },
    };
  }

  render() {
    translate.setLanguage("en");

    const { creator, isDemo, user } = this.props;
    const { showVideoModal } = user;

    const videoLimit = 120000;

    return (
      <div className="col-span-12 px-8 sm:px-4 md:pr-0 mx-auto creator-header-container hidden md:block">
        {showVideoModal && <RecordVideoModal creator={creator} />}
        <div className="flex flex-row no-gutters content items-center grid grid-cols-12 p-0 md:p-4 lg:p-8">
          <div className="col-span-12 md:col-span-3 mx-auto">
            <img
              className="h-32 w-32 rounded-full xl:w-40 xl:h-40 p-2"
              src={creator.avatar}
              alt=""
            />
          </div>
          <div className="col-span-12 md:col-span-9 header-content pl-2">
            <div className="titles d-none d-md-block">
              <h1 className="mb-2">{creator.name}</h1>

              <div className="subtitle text-gray-600 mb-2">
                {creator.shortIntro}
              </div>

              {creator.website && (
                <a
                  href={
                    creator.website.startsWith("https://") ||
                    creator.website.startsWith("http://")
                      ? creator.website
                      : "http://" + creator.website
                  }
                  target="_blank"
                  className="text-indigo-600 hover:text-indigo-500 weblink"
                >
                  {creator.website.startsWith("https://")
                    ? creator.website.replace("https://", "")
                    : creator.website.startsWith("http://")
                    ? creator.website.replace("http://", "")
                    : creator.website}
                </a>
              )}
            </div>
            <button
              className="btn btn-video py-2 px-3 mr-2"
              onClick={() =>
                this.props.setUserValue({
                  showVideoModal: true,
                  creatorId: creator.uid,
                  isDemo: isDemo,
                  videoLimit: videoLimit,
                })
              }
            >
              <i className="fas fa-video fa-lg mr-3"></i>
              {translate.recordVideoText}
            </button>
            {isDemo && (
              <button
                className="btn btn-support py-2 px-3"
                onClick={() => this.props.history.push("/dashboard-page-demo")}
              >
                {translate.demoDashboardTest}
                <i className="fas fa-arrow-right fa-lg ml-2"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreatorHeader);
