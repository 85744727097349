import React, { Suspense } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Layout from "./components/Layout/Layout";
import HomePage from "./pages/HomePage/HomePage";
import Pricing from "./pages/Pricing/Pricing";
import Dashboard from "./pages/Dashboard/Dashboard";
import SingleMessage from "./pages/SingleMessage/SingleMessage";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import RequestInvite from "./pages/Auth/RequestInvite";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import CreatorPage from "./pages/CreatorPage/CreatorPage";
import Profile from "./pages/Profile/Profile";
import Billing from "./pages/Billing/Billing";
import PaymentSuccess from "./pages/Payment/Success";
import PaymentLater from "./pages/Payment/Later";
import PaymentFailure from "./pages/Payment/Failure";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import NotFound from "./pages/ErrorPage/NotFound";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const stripePromise = loadStripe(
  "pk_live_51HdTLLIqdYxdRtVUdEKA9yApQWoYFd6ixu4nBHtiVRPDG0SM1NHoavkL3NacM1ns5hNVBnAKYT7vKYNCKfd40TlO00qoHESjMs"
);

class App extends React.Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Suspense fallback={this.Loader()}>
            <Elements stripe={stripePromise}>
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "Ac-FYXA8gH68bwTcpU-mhCFLW5WgM4iQK0jt9hv6-_DSKl3WnoiFtCQmojNsA4FKo_fEJ7HZvX5ngDq7",
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={(props) => (
                      <Layout>
                        <HomePage {...props} />
                      </Layout>
                    )}
                  />
                  {/* <Route
                  exact
                  path="/pricing"
                  component={(props) => (
                    <Layout>
                      <Pricing {...props} />
                    </Layout>
                  )}
                /> */}
                  <Route
                    exact
                    path="/dashboard-page-demo"
                    component={(props) => (
                      <Layout>
                        <Dashboard {...props} isDemo={true} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/messages"
                    component={(props) => (
                      <Layout>
                        <Dashboard {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/message/:messageId"
                    component={(props) => (
                      <Layout>
                        <SingleMessage {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/login"
                    component={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/signup"
                    component={(props) => <Signup {...props} />}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={(props) => <ForgotPassword {...props} />}
                  />
                  <Route
                    exact
                    path="/request"
                    component={(props) => <RequestInvite {...props} />}
                  />
                  <Route
                    exact
                    path="/profile"
                    component={(props) => (
                      <Layout>
                        <Profile {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/payment"
                    component={(props) => (
                      <Layout>
                        <Billing {...props} />
                      </Layout>
                    )}
                  />
                  {/* <Route
                  exact
                  path="/payment-success"
                  component={(props) => (
                    <Layout>
                      <PaymentSuccess {...props} />
                    </Layout>
                  )}
                />
                <Route
                  exact
                  path="/payment-later"
                  component={(props) => (
                    <Layout>
                      <PaymentLater {...props} />
                    </Layout>
                  )}
                />
                <Route
                  exact
                  path="/payment-failure"
                  component={(props) => (
                    <Layout>
                      <PaymentFailure {...props} />
                    </Layout>
                  )}
                /> */}
                  <Route
                    exact
                    path="/terms"
                    component={(props) => (
                      <Layout>
                        <Terms {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/privacy"
                    component={(props) => (
                      <Layout>
                        <Privacy {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/error"
                    component={(props) => (
                      <Layout>
                        <NotFound {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/creator-page-demo"
                    component={(props) => (
                      <Layout noFooter={true}>
                        <CreatorPage {...props} isDemo={true} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/:username"
                    component={(props) => (
                      <Layout noFooter={true}>
                        <CreatorPage {...props} />
                      </Layout>
                    )}
                  />
                  <Route
                    component={(props) => (
                      <Layout>
                        <NotFound {...props} />
                      </Layout>
                    )}
                  />
                </Switch>
              </PayPalScriptProvider>
            </Elements>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
