import { SET_USER_VALUE } from "../constant";

const initialState = {
  currentUser: null,
  creatorId: null,
  showSupportModal: false,
  showAudioModal: false,
  showVideoModal: false,
  showNoAnswerModal: false,
  showProfilePreparingModal: false,
  showRequestPayoutModal: false,
  showStripeSupportedCountriesModal: false,
  isDemo: false,
  videoLimit: null,
  isReply: false,
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
