import React from "react";
import { Helmet } from "react-helmet";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import CreatorHeader from "./CreatorHeader";
import CreatorDetails from "./CreatorDetails";
import CreatorVideo from "./CreatorVideo";
import "./CreatorPage.scss";

import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";

import "react-placeholder/lib/reactPlaceholder.css";

import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    notReadyTitle: "You haven't completed your profile",
    notReadyDesc:
      "In order to show your creator's page, you need to have a complete profile. Also to receive payments, you need to connect to your Stripe account. Please go to the Profile's page to continue.",
    setupBtn: "Set up profile",
  },
  sp: {
    //Spanish
    notReadyTitle: "No has completado tu perfil",
    notReadyDesc:
      "Para mostrar la página de su creador, debe tener un perfil completo. Además, para recibir pagos, debe iniciar sesión en su cuenta de Stripe. Vaya a la página de perfil para continuar.",
    setupBtn: "Configurar mi perfil",
  },
});

var unsubscribe;
var currentUserListener;

class CreatorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      creator: null,
      profileNotReady: false,
    };
  }

  isProfileCompleted = (creator) => {
    return (
      creator &&
      creator.videoFile &&
      creator.about &&
      creator.username !== "" &&
      creator.name !== "" &&
      creator.shortIntro &&
      creator.shortIntro !== ""
    );
  };

  componentDidMount = async () => {
    var username = this.props.match.params.username;

    this.props.isDemo
      ? setTimeout(() => this.setState({ isLoading: false }), 1000)
      : (unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
          try {
            const usernameRef = await firebase
              .database()
              .ref("usernames")
              .child(username)
              .once("value");

            if (!usernameRef.val()) {
              this.props.history.push("/error");
            } else {
              const userId = usernameRef.val().uid;

              if (user) {
                // user logged in
                if (usernameRef.val() === "profile not completed") {
                  currentUserListener = await firebase
                    .database()
                    .ref("users")
                    .child(user.uid)
                    .on("value", (snapshot) => {
                      this.setState({
                        isLoading: false,
                        creator: snapshot.val(),
                      });
                    });
                  return;
                } else {
                  firebase
                    .database()
                    .ref("users")
                    .child(userId)
                    .once("value", (snapshot) => {
                      if (this.isProfileCompleted(snapshot.val())) {
                        this.setState({
                          isLoading: false,
                          creator: snapshot.val(),
                        });
                      } else {
                        if (userId === user.uid) {
                          this.setState({
                            isLoading: false,
                            profileNotReady: true,
                          });
                        } else {
                          this.props.history.push("/error");
                        }
                      }
                    });
                }
              } else {
                firebase
                  .database()
                  .ref("users")
                  .child(userId)
                  .once("value", (snapshot) => {
                    if (this.isProfileCompleted(snapshot.val())) {
                      this.setState({
                        isLoading: false,
                        creator: snapshot.val(),
                      });
                    } else {
                      this.props.history.push("/error");
                    }
                  });
              }
            }
          } catch (err) {
            this.props.history.push("/error");
          }
        }));
  };

  render() {
    const { isLoading, creator, profileNotReady } = this.state;
    const { isDemo } = this.props;

    if (profileNotReady) {
      return (
        <div className="container mx-auto px-6 py-6 lg:px-64 lg:py-24 no-creator-page-container">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="sm:flex sm:items-start sm:justify-between">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {translate.notReadyTitle}
                  </h3>
                  <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>{translate.notReadyDesc}</p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  <span className="inline-flex rounded-md shadow-sm">
                    <a
                      href="/profile"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                    >
                      {translate.setupBtn}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const videoSrc = isDemo
      ? "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/demo-data%2Fdemo-youtuber.mp4?alt=media&token=20fadf6f-eeec-47d4-ab29-e589752be7fe"
      : creator && creator.videoFile;

    const demoCreator = {
      name: "Emma Perry",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/demo-data%2Femm-avatar.png?alt=media&token=cfadf492-797f-4ba7-bcb9-0bd127ab7cb6",
      shortIntro: "Growth hacker in the day ☀️, Yoga teacher at night 🌙",
      about:
        "I'm currently a growth hacker at Veedyo in San Francisco.\n\n If you want to ask me anything growth hacking related, feel free to drop me a message 👇.\n\nBeyond work I am also a certified Yoga 🧘‍♀️ instructor, and an amateur YouTuber. I love to share my work and life stories in my YouTube channel.\n\n💜 **ASK ME ANYTHING!** - I will provide valuable feedback + engaging response + happy to help.\n\n Most of the answers are base on my opinions, skills, knowledge & experience.\n\n You can ask relevant questions, and I will be happy to give you my most honest reply.",
      uid: "123456",
      answerPrice: 25,
      respondSpeed: "one day",
      paymentMethod: "stripe",
    };

    const demoPoster =
      "https://firebasestorage.googleapis.com/v0/b/influenswer.appspot.com/o/demo-data%2Fdemo-youtuber-poster.png?alt=media&token=77d021ef-d20b-4476-a8cc-d266645156a1";

    const videoPlaceholder = (
      <RectShape
        color="#cdcdcd"
        style={{ width: "auto", height: 640, borderRadius: "8px" }}
      />
    );

    if (isLoading || (!creator && !isDemo)) {
      return (
        <div
          className="profile-page-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="container rounded p-0 mx-auto">
            <div className="row no-gutters content items-center profile-container grid grid-cols-12 p-0 md:p-4 lg:p-8">
              <div className="col-span-12 md:col-span-5">
                <div className="ml-0 video-wrapper">
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    customPlaceholder={videoPlaceholder}
                  ></ReactPlaceholder>
                </div>
              </div>
              <div className="col-span-12 mt-5 md:col-span-7 md:mt-0 text-content px-8 sm:px-4 xs:px-0">
                <div className="about-text" style={{ overflowY: "hidden" }}>
                  <ReactPlaceholder
                    showLoadingAnimation={true}
                    type="text"
                    rows={7}
                  ></ReactPlaceholder>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="profile-page-container">
        {creator && (
          <Helmet>
            <title>{creator.name + " | Influenswer"}</title>
            <meta name="description" content={creator.shortIntro} />
          </Helmet>
        )}
        <div className="container rounded p-0 mx-auto">
          <div className="row no-gutters content items-center profile-container grid grid-cols-12 p-0 md:p-4 lg:p-8">
            <CreatorHeader
              creator={isDemo ? demoCreator : creator}
              isDemo={isDemo}
            />
            <CreatorVideo
              videoSrc={videoSrc}
              videoPoster={isDemo ? demoPoster : creator.videoPoster || ""}
              isDemo={isDemo}
            />
            <CreatorDetails
              creator={isDemo ? demoCreator : creator}
              isDemo={isDemo}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreatorPage;
