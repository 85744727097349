import React from "react";
import Localizedtranslate from "react-localization";

let translate = new Localizedtranslate({
  en: {
    //English
    filter: "FILTER",
    all: "All",
    archived: "Archived",
  },
  sp: {
    //Spanish
    filter: "FILTRAR",
    all: "Todas",
    archived: "Archivada",
  },
});

class Sidebar extends React.Component {
  render() {
    translate.setLanguage("en");

    const { tab, messages, archived } = this.props;

    const allCount = messages.length;
    const pendingList = messages.filter((item) => !item.reply),
      pendingCount = pendingList.length;
    const repliedList = messages.filter((item) => item.reply),
      repliedCount = repliedList.length;
    const noAnswerList = messages.filter((item) => item.refund),
      noAnswerCount = noAnswerList.length;

    return (
      <div className="bg-white overflow-hidden mx-4 col-span-12 md:col-span-4">
        <nav className="md:w-3/4 sm:w-full ml-auto mt-10">
          <h1 className="mx-2 my-2 text-gray-400 text-base font-bold">
            {translate.filter}
          </h1>
          <button
            className={`w-full mt-1 group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-500 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-blue-600 focus:bg-blue-100 transition ease-in-out duration-150 ${
              tab === "all" ? "text-blue-600 bg-blue-100" : ""
            }`}
            aria-current="page"
            onClick={() => this.props.onChangeTab("all")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle"
              className="svg-inline--fa fa-circle fa-w-16 w-2 mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#4299e1"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
              ></path>
            </svg>
            {translate.all}
            <span className="ml-auto px-4 py-1">{allCount}</span>
          </button>

          <button
            className={`w-full mt-1 group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-500 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-orange-600 focus:bg-orange-100 transition ease-in-out duration-150 ${
              tab === "pending" ? "text-orange-600 bg-orange-100" : ""
            }`}
            onClick={() => this.props.onChangeTab("pending")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle"
              className="svg-inline--fa fa-circle fa-w-16 w-2 mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#f6ad55"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
              ></path>
            </svg>
            Pending
            <span className="ml-auto px-4 py-1">{pendingCount}</span>
          </button>

          <button
            className={`w-full mt-1 group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-500 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-green-600 focus:bg-green-100 transition ease-in-out duration-150 ${
              tab === "replied" ? "text-green-600 bg-green-100" : ""
            }`}
            onClick={() => this.props.onChangeTab("replied")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle"
              className="svg-inline--fa fa-circle fa-w-16 w-2 mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#48bb78"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
              ></path>
            </svg>
            Replied
            <span className="ml-auto px-4 py-1">{repliedCount}</span>
          </button>

          <button
            className={`w-full mt-1 group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-500 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-red-600 focus:bg-red-100 transition ease-in-out duration-150 ${
              tab === "noAnswer" ? "text-red-600 bg-red-100" : ""
            }`}
            onClick={() => this.props.onChangeTab("noAnswer")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle"
              className="svg-inline--fa fa-circle fa-w-16 w-2 mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#f05252"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
              ></path>
            </svg>
            No answer
            <span className="ml-auto px-4 py-1">{noAnswerCount}</span>
          </button>
          <button
            className={`w-full mt-1 group flex items-center px-3 py-2 text-base leading-5 font-medium text-gray-500 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150 ${
              tab === "archived" ? "text-gray-600 bg-gray-100" : ""
            }`}
            onClick={() => this.props.onChangeTab("archived")}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle"
              className="svg-inline--fa fa-circle fa-w-16 w-2 mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
              ></path>
            </svg>
            {translate.archived}
            <span className="ml-auto px-4 py-1">{archived.length}</span>
          </button>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
